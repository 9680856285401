import { TaskBillingInvoiceItem } from '../dto';

const getDefaults = (): TaskBillingInvoiceItem => ({
  id: 1,
  amount: 0,
  billingInvoiceId: 1,
  description: '',
  fullDescription: '',
  quantity: 0,
  taxPercent: 0,
  totalAmount: 0,
  totalAmountIncludingTaxes: 0,
});

export const getTaskBillingInvoiceItemMock = (p?: Partial<TaskBillingInvoiceItem>): TaskBillingInvoiceItem => ({
  ...getDefaults(),
  ...p,
});
