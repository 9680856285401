import { PaymentMethodTypes } from '@dougs/subscription/dto';
import { getUserMock, Spouse } from '@dougs/user/dto';
import { Task } from '../dto';

const getDefaults = (): Task => ({
  id: 1,
  companyId: 1,
  version: 1,
  title: 'title',
  code: 'code',
  category: 'category',
  dueDate: new Date('2021-11-08'),
  enabledAt: new Date('2021-11-08'),
  overdue: true,
  firstCompletedAt: new Date('2021-11-08'),
  completedAt: new Date('2021-11-08'),
  billingInvoiceItems: [],
  abortionReasons: [],
  targetId: 1,
  isPriority: false,
  abortedAt: new Date('2021-11-08'),
  deletedAt: new Date('2021-11-08'),
  createdAt: new Date('2021-11-08'),
  startDate: new Date('2021-11-08'),
  quoteStatus: 'ready',
  isActive: false,
  isService: false,
  isDelegated: false,
  hasRemarks: false,
  target: getUserMock(),
  attachments: [],
  comments: [],
  completionReasons: [],
  completionReasonCode: '',
  completionReasonMessage: '',
  completer: {
    id: 1,
    profile: {
      id: 1,
      alternateAvatarUrl: '',
      fullName: '',
      avatarUrl: '',
      email: '',
      normalizedPhones: [],
      firstName: '',
      lastName: '',
      phone: '',
      workPhone: '',
      isMan: false,
      identityProof: [],
      spouse: {} as Spouse,
      initials: '',
    },
  },
  context: {},
  billingAmountExcludingTaxes: 1,
  targetTitle: '',
  policy: {
    isCompletable: true,
  },
  metadata: {
    closingDate: new Date('2021-11-08'),
    completionPercent: 10,
    isLiquidation: false,
    preventNotifications: false,
    paymentMethod: PaymentMethodTypes.CARD,
    activeUrl: '',
    declarationId: 1,
    calendlyUrl: '',
    isBillable: false,
    billOnCompletion: false,
    skipCustomerAcceptation: false,
    ccnAnalysisAttachmentIds: [],
  },
  parentId: null,
  masterTaskId: null,
  subTaskCount: 1,
  tasks: [],
  completedSubTaskCount: 0,
  activeSubTaskTitle: '',
  activeSubTaskCode: '',
  lastCommentAt: new Date('2021-11-25'),
  assignee: getUserMock(),
  assigneeId: null,
  hasForm: false,
  isConfigurationTrigger: false,
  isConfigurator: false,
  isProcess: true,
  rootId: 1,
  unseen: false,
  workload: 70,
  index: 0,
  onHold: false,
  unseenMention: false,
  mention: null,
  assigneeSeenAt: null,
  customerConversations: [],
});

export const getTaskMock = (p?: Partial<Task>): Task => ({
  ...getDefaults(),
  ...p,
});
