import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { toPromise } from '@dougs/core/utils';
import {
  CreatableTaskTemplate,
  MailPreview,
  Task,
  TaskConfiguratorContextVariableAnyType,
  TaskForm,
  TaskFormInlineGroupValueItem,
  TaskTemplate,
} from '@dougs/task/dto';
import { TemplateTaskHttpService } from '../../http/template-task.http';

@Injectable({
  providedIn: 'root',
})
export class TemplateTaskStateService {
  constructor(
    private readonly logger: LoggerService,
    private readonly templateTaskHttpService: TemplateTaskHttpService,
  ) {}

  async getTaskTemplate(taskCode: string, taskVersion?: number): Promise<TaskTemplate | null> {
    try {
      return await lastValueFrom(this.templateTaskHttpService.getTaskTemplate(taskCode, taskVersion));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getCreatableTaskTemplate(): Promise<CreatableTaskTemplate[] | null> {
    try {
      return await lastValueFrom(this.templateTaskHttpService.getCreatableTaskTemplate());
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getMailPreview(taskId: number): Promise<MailPreview | null> {
    try {
      return await toPromise(this.templateTaskHttpService.getMailPreview(taskId));
    } catch (error) {
      this.logger.error(error);
      return null;
    }
  }

  async getTaskForm(task: Task): Promise<TaskForm | null> {
    try {
      return await lastValueFrom(this.templateTaskHttpService.getTaskForm(task.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async changeTaskForm(task: Task, items: TaskFormInlineGroupValueItem[] | TaskForm): Promise<TaskForm | null> {
    try {
      return await lastValueFrom(this.templateTaskHttpService.changeTaskForm(task.id, items));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateTaskForm(task: Task, items: TaskFormInlineGroupValueItem[] | TaskForm): Promise<TaskForm | null> {
    try {
      return await lastValueFrom(this.templateTaskHttpService.updateTaskForm(task.id, items));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getTaskConfiguration(task: Task): Promise<TaskForm | null> {
    try {
      return await lastValueFrom(this.templateTaskHttpService.getTaskConfiguration(task.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getTaskConfigurationV2(task: Task): Promise<TaskConfiguratorContextVariableAnyType[]> {
    try {
      return await lastValueFrom(this.templateTaskHttpService.getTaskConfigurationV2(task.parentId ?? task.id));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  async getSubTaskConfigurationV2(
    rootTaskId: number,
    subTaskId: number,
  ): Promise<TaskConfiguratorContextVariableAnyType[]> {
    try {
      return await lastValueFrom(this.templateTaskHttpService.getSubTaskConfigurationV2(rootTaskId, subTaskId));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }
}
