import { CategoryStats } from '../dto';

const getDefaults = (): CategoryStats => ({
  overdueTaskCount: 0,
  rootTaskCount: 0,
  categories: [],
  unseenMentionsCount: 0,
});

export const getCategoryStatsMock = (p?: Partial<CategoryStats>): CategoryStats => ({
  ...getDefaults(),
  ...p,
});
