import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Task } from '@dougs/task/dto';
import { AbstractTaskState } from './abstract-task.state';

@Injectable({
  providedIn: 'root',
})
export class SupervisionTaskStateService extends AbstractTaskState<{ supervisionTask: Task | null }> {
  readonly supervisionTask$: Observable<Task | null> = this.select((state) => state.supervisionTask);

  async refreshTasks(taskId: number): Promise<boolean> {
    try {
      this.setState({
        supervisionTask: await lastValueFrom(this.taskHttpService.getTaskById(taskId)),
      });
      return true;
    } catch (e) {
      this.logger.error(e);
      this.setState({
        supervisionTask: null,
      });
      return false;
    }
  }

  resetSupervisionTask(): void {
    this.setState({
      supervisionTask: null,
    });
  }

  protected handleTaskModification(task: Task): void {
    if (task.id === this.state?.supervisionTask?.id) {
      this.setState({
        supervisionTask: task,
      });
    }
  }

  protected removeTaskState(task: Task): void {
    if (task.id === this.state?.supervisionTask?.id) {
      this.resetSupervisionTask();
    }
  }
}
