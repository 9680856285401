import { CodeCategoryTask } from '../dto';

const getDefaults = (): CodeCategoryTask => ({
  overdueTaskCount: 0,
  rootTaskCount: 0,
  unseenMentionsCount: 0,
  label: '',
  code: '',
  category: '',
});

export const getCodeCategoryStatsMock = (p?: Partial<CodeCategoryTask>): CodeCategoryTask => ({
  ...getDefaults(),
  ...p,
});
