export interface CockpitUnseenStats {
  tasks: number;
  mentions: number;
}

export interface CockpitStatsQuantityPair {
  workload: number;
  count: number;
}

export interface CockpitStatsIntercom {
  closed: number;
  open: number | null;
  snoozed: number | null;
  openUpdatedAt: string | null;
}

export interface CockpitTeamStats {
  teamId: number;
  capacity: number;

  tasksTotal: CockpitStatsQuantityPair;
  tasksCompletedThisWeek: CockpitStatsQuantityPair;
  tasksTodo: CockpitStatsQuantityPair;
  tasksRemaining: CockpitStatsQuantityPair;

  appointmentsCompleted: CockpitStatsQuantityPair;

  intercom: CockpitStatsIntercom;
}

export interface CockpitCollaboratorStats {
  userId: number;
  collaboratorId: number;

  tasksTotal: CockpitStatsQuantityPair;
  tasksCompletedThisWeek: CockpitStatsQuantityPair;
  tasksTodo: CockpitStatsQuantityPair;
  tasksRemaining: CockpitStatsQuantityPair;

  appointmentsTodo: CockpitStatsQuantityPair;
  appointmentsCompletedThisWeek: CockpitStatsQuantityPair;

  intercom: CockpitStatsIntercom;
}

export interface CockpitAccountingTeamStats {
  isCompletedStats: boolean;
  activity: CockpitAccountingStatsActivity;
  collaborators: CockpitAccountingStatsCollaborator[];
}

export interface CockpitAccountingStatsActivity {
  points: number;
  priorityTasks: number;
  balanceSheets: number;
}

export interface CockpitAccountingStatsCollaborator {
  id: number;
  userId: number;
  profile: {
    fullName: string;
    avatarUrl: string;
  };
  activity: CockpitAccountingStatsCollaboratorActivity;
  balanceSheets?: CockpitAccountingStatsCollaboratorBalanceSheets;
}

export interface CockpitAccountingStatsCollaboratorActivity {
  priorityTasks: number;
  balanceSheets: number;
  pointsAssignedThisWeek?: number;
  pointsAssignedBeforeThisWeek?: number;
  pointsCompletedThisWeek?: number;
  averageProductivity: number;
}

export interface CockpitAccountingStatsCollaboratorBalanceSheets {
  accountingSurveyCompleted: number;
  prepared: number;
  accountingSurveyVerified: number;
  revisionCompleted: number;
}

export interface CockpitAccountingStatsCollaboratorIntercom {
  open?: number;
  openUpdatedAt?: Date;
  closed: number;
  snoozed?: number;
}

export enum CockpitActivity {
  ASSIGNED_THIS_WEEK = 'assignedThisWeek',
  ASSIGNED_BEFORE_WEEK = 'assignedBeforeWeek',
  COMPLETED = 'completed',
  PRODUCTIVITY = 'productivity',
}

export enum CockpitBilan {
  COMPLETED = 'completed',
  PREPARED = 'prepared',
  VERIFIED = 'verified',
  REVISION = 'revision',
}
