import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { CollaboratorVariable, Payslip, PayslipModalInfo } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class PayslipHttpService {
  constructor(private readonly http: HttpClient) {}

  getPayslipModalInfos(companyId: number, taskId: number): Observable<PayslipModalInfo> {
    return this.http.get<PayslipModalInfo>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/social/payslips`,
      { params: { taskId: taskId.toString() } },
    );
  }

  getPayslipById(companyId: number, payslipId: number): Observable<Payslip> {
    return this.http.get<Payslip>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/social/payslips/${payslipId}`,
    );
  }

  getSilaeVariablesCatalog(companyId: number): Observable<CollaboratorVariable[]> {
    return this.http.get<CollaboratorVariable[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/social/payslips/variable-catalog`,
    );
  }

  saveCollaboratorVariables(
    companyId: number,
    payslipId: number,
    collaboratorVariables: CollaboratorVariable[],
  ): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/social/payslips/${payslipId}`,
      { variables: collaboratorVariables },
    );
  }

  validateCollaboratorVariables(companyId: number, payslipId: number): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/social/payslips/${payslipId}/validate-collaborator-variables`,
      {},
    );
  }

  unvalidateCollaboratorVariables(companyId: number, payslipId: number): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/social/payslips/${payslipId}/unvalidate-collaborator-variables`,
      {},
    );
  }

  validatePayslip(companyId: number, taskId: number): Observable<PayslipModalInfo> {
    return this.http.post<PayslipModalInfo>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/social/payslips/validate-variables`,
      { taskId },
    );
  }
}
