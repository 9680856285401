import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Activity, Comment } from '@dougs/task/dto';
import { ActivityHttpService } from '../http/activity.http';

interface ActivityState {
  activities: Activity[];
  permanentActivities: Activity[];
}

@Injectable({
  providedIn: 'root',
})
export class ActivityStateService extends StateService<ActivityState> {
  readonly activities$: Observable<Activity[]> = this.select((state) =>
    state.activities.filter((activity) => activity.type !== 'session' && activity.type !== 'interaction'),
  );
  readonly permanentActivitiesNotificationCount$: Observable<number> = this.select(
    (state) =>
      state.permanentActivities?.filter(
        (activity) => activity.type !== 'company.signup' && activity.type !== 'subscription.activated',
      )?.length ?? 0,
  );

  constructor(
    private readonly activityHttpService: ActivityHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshActivities(activeCompany: Company, onlyPermanent = false): Promise<void> {
    try {
      const activities: Activity[] =
        (await lastValueFrom(this.activityHttpService.getActivities(activeCompany.id, onlyPermanent)))?.reverse() ?? [];
      this.setState({
        activities,
        permanentActivities: onlyPermanent ? activities : this.state?.permanentActivities || [],
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateComment(activity: Activity, company: Company, comment: Comment): Promise<void> {
    try {
      const commentUpdated: Comment = await lastValueFrom(
        this.activityHttpService.updateCommentActivity(company.id, comment),
      );

      this.setState({
        activities: this.state.activities.map((activityIterated) =>
          activity.id === activityIterated.id
            ? {
                ...activityIterated,
                data: {
                  ...activityIterated.data,
                  comment: commentUpdated,
                },
              }
            : activityIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadCommentAttachments(
    activity: Activity,
    company: Company,
    comment: Comment,
    files: FileList,
  ): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) =>
          lastValueFrom(this.activityHttpService.uploadCommentAttachment(company.id, comment.id, file)),
        ),
      );
      this.setState({
        activities: this.state.activities.map((activityIterated) =>
          activity.id === activityIterated.id
            ? {
                ...activityIterated,
                data: {
                  ...activityIterated.data,
                  comment: {
                    ...comment,
                    attachments: [...(comment.attachments || []), ...attachments],
                  },
                },
              }
            : activityIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removeCommentAttachment(
    activity: Activity,
    company: Company,
    comment: Comment,
    attachment: Attachment,
  ): Promise<void> {
    try {
      await lastValueFrom(this.activityHttpService.deleteCommentAttachment(company.id, attachment));
      this.setState({
        activities: this.state.activities.map((activityIterated) =>
          activity.id === activityIterated.id
            ? {
                ...activityIterated,
                data: {
                  ...activityIterated.data,
                  comment: {
                    ...comment,
                    attachments: comment.attachments.filter(
                      (commentAttachment) => commentAttachment.id !== attachment.id,
                    ),
                  },
                },
              }
            : activityIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
