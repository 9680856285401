import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { BillingInvoiceItem } from '@dougs/subscription/dto';
import {
  CockpitCategorySummary,
  CockpitTask,
  Comment,
  CommentPost,
  CreateTaskDto,
  Task,
  TASK_LEGAL_STATE,
  TaskBillingInvoiceItem,
  TaskConfiguratorContext,
  TaskESignProcedure,
  TaskForm,
  TaskFormInlineGroupValueItem,
} from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class TaskHttpService {
  constructor(private readonly http: HttpClient) {}

  getTasks(params: HttpParams = new HttpParams(), limit = 10, offset = 0): Observable<Task[]> {
    params = params.append('limit', limit).append('offset', offset);
    return this.http.get<Task[]>(`${AppConfig.settings.legacyApiServerUrl}/tasks`, {
      params,
    });
  }

  getTasksV2(
    params: HttpParams = new HttpParams(),
    pageSize = 20,
    lastTaskId?: number,
    activeSortKey?: string,
    activeSortValue?: string,
  ): Observable<Task[]> {
    params = params.append('pageSize', pageSize);
    if (lastTaskId && activeSortKey) {
      params = params.append('lastId', lastTaskId).append(`last_${activeSortKey}`, activeSortValue || '');
    }
    return this.http.get<Task[]>(`${AppConfig.settings.legacyApiServerUrl}/tasks/v2`, {
      params,
    });
  }

  getControlPanelTasks(params: HttpParams = new HttpParams(), lastTaskId?: number): Observable<Task[]> {
    if (lastTaskId) {
      params = params.append('lastId', lastTaskId);
    }
    return this.http.get<Task[]>(`${AppConfig.settings.legacyApiServerUrl}/tasks/control-panel`, {
      params,
    });
  }

  getSupportTasks(params: HttpParams = new HttpParams()): Observable<Task[]> {
    return this.http.get<Task[]>(`${AppConfig.settings.legacyApiServerUrl}/tasks/tech-supports`, {
      params,
    });
  }

  getTeamTasks(teamId: number, params = new HttpParams()): Observable<CockpitTask[]> {
    return this.http.get<CockpitTask[]>(`${AppConfig.settings.legacyApiServerUrl}/tasks/team/${teamId}`, {
      params,
    });
  }

  getTeamTasksSummaries(teamId: number, params = new HttpParams()): Observable<CockpitCategorySummary[]> {
    return this.http.get<CockpitCategorySummary[]>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/summaries/team/${teamId}`,
      {
        params,
      },
    );
  }

  getCollaboratorTasks(collaboratorId: number, params = new HttpParams()): Observable<CockpitTask[]> {
    return this.http.get<CockpitTask[]>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/collaborator/${collaboratorId}`,
      {
        params,
      },
    );
  }

  getCollaboratorTasksSummaries(
    collaboratorId: number,
    params = new HttpParams(),
  ): Observable<CockpitCategorySummary[]> {
    return this.http.get<CockpitCategorySummary[]>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/summaries/collaborator/${collaboratorId}`,
      {
        params,
      },
    );
  }

  getCollaboratorTasksWithLatestMention(
    collaboratorId: number,
    params = new HttpParams(),
    page?: number,
  ): Observable<CockpitTask[]> {
    if (page !== undefined) {
      params = params.append('page', page);
    }

    return this.http.get<CockpitTask[]>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/mentions/collaborator/${collaboratorId}/latest`,
      {
        params,
      },
    );
  }

  getCollaboratorUnseenTasks(
    collaboratorId: number,
    params = new HttpParams(),
    page?: number,
  ): Observable<CockpitTask[]> {
    if (page !== undefined) {
      params = params.append('page', page);
    }

    return this.http.get<CockpitTask[]>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/unseen/collaborator/${collaboratorId}`,
      {
        params,
      },
    );
  }

  getDepartmentTasks(department: string, params = new HttpParams()): Observable<CockpitTask[]> {
    return this.http.get<CockpitTask[]>(`${AppConfig.settings.legacyApiServerUrl}/tasks/department/${department}`, {
      params,
    });
  }

  getDepartmentTasksSummaries(department: string, params = new HttpParams()): Observable<CockpitCategorySummary[]> {
    return this.http.get<CockpitCategorySummary[]>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/summaries/department/${department}`,
      {
        params,
      },
    );
  }

  getUserNotificationTasks(userId: number, companyId: number): Observable<Task[]> {
    return this.http.get<Task[]>(
      `${AppConfig.settings.legacyApiServerUrl}/users/${userId}/tasks?companyId=${companyId}&completedAt=null&orderBy=["dueDate","ASC"]&orderBy=["id","ASC"]&rootId=null&full=true`,
    );
  }

  getCustomerTasks(companyId: number, taskCodes?: string[], unseen?: boolean): Observable<Task[]> {
    let params = new HttpParams();
    if (taskCodes?.length) {
      taskCodes.forEach((taskCode) => (params = params.append('code[]', taskCode)));
    }
    if (unseen !== undefined) {
      params = params.append('unseen', unseen);
    }
    return this.http.get<Task[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/customer-tasks`, {
      params,
    });
  }

  processTaskConfiguration(taskId: number, items: TaskFormInlineGroupValueItem[] | TaskForm): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/configuration`, {
      configResult: items,
    });
  }

  processTaskConfigurationV2(taskId: number, items: TaskConfiguratorContext): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/configurationV2`, {
      context: items,
    });
  }

  updateTaskConfigurationV2(taskId: number, items: TaskConfiguratorContext): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/actions/update-context`, {
      context: items,
    });
  }

  getTaskRemarks(taskId: number): Observable<string> {
    return this.http.get<string>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/remarks`);
  }

  commentTask(taskId: number, comment: CommentPost): Observable<Comment> {
    return this.http.post<Comment>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/comments`, comment);
  }

  uploadCommentAttachment(taskId: number, commentId: number, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/comments/${commentId}/attachments?filename=${file.name}&type=attachment`,
      formData,
    );
  }

  getTaskAttachments(taskId: number): Observable<Attachment[]> {
    return this.http.get<Attachment[]>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/attachments`);
  }

  uploadTaskAttachment(
    taskId: number,
    file: File,
    options: {
      fileType?: string;
      metadata?: { partnerId: number; variableCreatedAt?: string };
    },
  ): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    let params = new HttpParams().append('filename', file.name);

    if (options.fileType) {
      params = params.append('type', options.fileType);
    }

    if (options.metadata) {
      params = params.append('metadata', JSON.stringify(options.metadata));
    }

    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/attachments`,
      formData,
      {
        params,
      },
    );
  }

  deleteCommentAttachment(taskId: number, attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/comments/${attachment.modelId}/attachments/${attachment.id}`,
    );
  }

  deleteTaskAttachment(attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${attachment.modelId}/attachments/${attachment.id}`,
    );
  }

  createTask(task: CreateTaskDto): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks`, task);
  }

  getTaskNote(userId: number): Observable<Task> {
    return this.http.get<Task>(`${AppConfig.settings.legacyApiServerUrl}/users/${userId}/tasks/notes`);
  }

  updateInvoiceTaskSettings(task: Task): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}/offers/update-invoicing-settings`,
      task.metadata,
    );
  }

  addService(task: Task, billingInvoiceItems: BillingInvoiceItem[]): Observable<BillingInvoiceItem[]> {
    return this.http.post<BillingInvoiceItem[]>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}/offers/create-intermittent-service`,
      billingInvoiceItems,
    );
  }

  deleteService(task: Task, billingInvoiceItem: TaskBillingInvoiceItem): Observable<BillingInvoiceItem> {
    return this.http.post<BillingInvoiceItem>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}/offers/delete-service`,
      billingInvoiceItem,
    );
  }

  updateTask(task: Task): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}`, task);
  }

  updateTaskDueDate(task: Task, dueDate: string, reason?: string): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}/actions/update-due-date`, {
      dueDate,
      reason,
    });
  }

  getTask(taskId: number, seen = false): Observable<Task> {
    return this.http.get<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}?seen=${seen}`);
  }

  getUserTasks(userId: number, companyId: number, taskCode?: string): Observable<Task[]> {
    let params: HttpParams = new HttpParams().append('companyId', companyId);
    if (taskCode) {
      params = params.append('code', taskCode);
    }

    return this.http.get<Task[]>(`${AppConfig.settings.legacyApiServerUrl}/users/${userId}/tasks`, { params });
  }

  updateCommentTask(taskId: number, comment: Comment): Observable<Comment> {
    return this.http.post<Comment>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/comments/${comment.id}`,
      comment,
    );
  }

  assignTask(taskId: number, assigneeId: number | null): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/actions/assign`, {
      assigneeId,
    });
  }

  bulkAssignTasks(taskIds: number[], assigneeId: number | null, startDate?: Date | string): Observable<Task[]> {
    return this.http.post<Task[]>(`${AppConfig.settings.legacyApiServerUrl}/tasks/actions/bulk-assign`, {
      assigneeId,
      taskIds,
      startDate,
    });
  }

  bulkUpdateTasks(taskIds: number[], startDate: Date | string): Observable<Task[]> {
    return this.http.post<Task[]>(`${AppConfig.settings.legacyApiServerUrl}/tasks/actions/bulk-update`, {
      ids: taskIds,
      data: {
        startDate,
      },
    });
  }

  bulkUpdateAssigneeTasks(taskIds: number[], assigneeId: number): Observable<Task[]> {
    return this.http.post<Task[]>(`${AppConfig.settings.legacyApiServerUrl}/tasks/actions/bulk-update`, {
      ids: taskIds,
      data: {
        assigneeId,
      },
    });
  }

  completeTask(
    taskId: number,
    bypass = false,
    completionReasonCode?: string,
    completionReasonMessage?: string,
  ): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/actions/complete`, {
      completionReasonCode,
      completionReasonMessage,
      bypass,
    });
  }

  unCompleteTask(taskId: number): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/actions/uncomplete`, {});
  }

  getAttachmentsByIds(task: Task, ids: number[]): Observable<Attachment[]> {
    return this.http.get<Attachment[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${task.companyId}/all-attachments`,
      { params: { ids } },
    );
  }

  getNegativeAssociateAccountTaskTitles(companyId: number): Observable<string[]> {
    return this.http.get<string[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting/negative-associate-accounts`,
    );
  }

  markUserTasksAsSeen(userId: number, tasks: Task[]): Observable<void> {
    const ids: number[] = tasks.map((task) => task.id);
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/users/${userId}/tasks/actions/mark-user-tasks-as-seen`,
      { ids },
    );
  }

  askCustomerForInformation(
    task: Task,
    subject: string,
    question: string,
    daysToReminder: number,
    daysToDueDate: number,
  ): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}/actions/ask-customer-for-information`,
      { subject, question, daysToReminder, daysToDueDate },
    );
  }

  abortTask(task: Task, abortionReasonCode: string, abortionReasonMessage: string): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}/actions/abort`, {
      abortionReasonCode,
      abortionReasonMessage,
    });
  }

  updateTaskStartDate(task: Task, startDate: string): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}/actions/update-start-date`, {
      startDate,
    });
  }

  priorizeTask(taskId: number): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/actions/priorize`, {});
  }

  depriorizeTask(taskId: number): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/actions/depriorize`, {});
  }

  updateWorkload(taskId: number, workload: number): Observable<void> {
    return this.http.post<void>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/actions/update-workload`, {
      workload,
    });
  }

  restoreTask(task: Task): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}/actions/restore`, {});
  }

  deleteTask(task: Task): Observable<void> {
    return this.http.delete<void>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}`);
  }

  getTaskById(taskId: number): Observable<Task> {
    return this.http.get<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}`);
  }

  createESignProcedure(taskId: number, partnerIds: number[], filesIds: number[]): Observable<TaskESignProcedure> {
    return this.http.post<TaskESignProcedure>(
      `${AppConfig.settings.legacyApiServerUrl}/yousign/${taskId}/esign-procedure-link`,
      {
        partnerIds,
        filesIds,
      },
    );
  }

  updateTaskLegalState(task: Task, legalState: TASK_LEGAL_STATE | null): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}/taskFilters`, {
      legalState,
    });
  }

  makeDocumentsAvailable(taskId: number, fileAttachmentIds: number[]): Observable<void> {
    return this.http.post<void>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/actions/provide-documents`, {
      fileAttachmentIds,
    });
  }
}
