import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import {
  CockpitAccountingTeamStats,
  CockpitCollaboratorStats,
  CockpitTeamStats,
  CockpitUnseenStats,
} from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class CockpitHttpService {
  constructor(private readonly http: HttpClient) {}

  getCockpitUnseenStats(collaboratorId: number): Observable<CockpitUnseenStats> {
    return this.http.get<CockpitUnseenStats>(
      `${AppConfig.settings.legacyApiServerUrl}/cockpit/stats/unseen/${collaboratorId}`,
    );
  }

  getCockpitCollaboratorStats(collaboratorId: number, at: Date): Observable<CockpitCollaboratorStats> {
    const params: HttpParams = new HttpParams().append('at', at.toLocaleString('en-US', { timeZone: 'Europe/Paris' }));

    return this.http.get<CockpitCollaboratorStats>(
      `${AppConfig.settings.legacyApiServerUrl}/cockpit/stats/collaborator/${collaboratorId}`,
      {
        params,
      },
    );
  }

  getCockpitTeamStats(teamId: number, at: Date): Observable<CockpitCollaboratorStats[]> {
    const params: HttpParams = new HttpParams().append('at', at.toLocaleString('en-US', { timeZone: 'Europe/Paris' }));

    return this.http.get<CockpitCollaboratorStats[]>(
      `${AppConfig.settings.legacyApiServerUrl}/cockpit/stats/team/${teamId}`,
      {
        params,
      },
    );
  }

  getAccountingTeamStats(teamId: number, at: Date): Observable<CockpitAccountingTeamStats> {
    const params: HttpParams = new HttpParams().append('at', format(at, 'yyyy-MM-dd'));

    return this.http.get<CockpitAccountingTeamStats>(
      `${AppConfig.settings.legacyApiServerUrl}/cockpit/stats/accounting/team/${teamId}`,
      { params },
    );
  }

  getCockpitDepartmentStats(department: string, at: Date): Observable<CockpitTeamStats[]> {
    const params: HttpParams = new HttpParams().append('at', at.toLocaleString('en-US', { timeZone: 'Europe/Paris' }));

    return this.http.get<CockpitTeamStats[]>(
      `${AppConfig.settings.legacyApiServerUrl}/cockpit/stats/department/${department}`,
      {
        params,
      },
    );
  }
}
