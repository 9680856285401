import { SubscriptionPlan } from '../dto';

const getDefaultsSubscriptionPlan: () => SubscriptionPlan = (): SubscriptionPlan => ({
  id: '',
  amount: 0,
  branding: '',
  description: '',
  hasAdjustment: false,
  isActive: false,
  isAvailable: false,
  isReferred: false,
  group: '',
  minimumAmountByFiscalYear: 0,
  name: '',
  upcomingInvoices: [],
  adjustment: {
    amount: 0,
    dayCount: 0,
    discountPercent: 0,
    endAt: new Date('2024-01-30'),
    startAt: new Date('2024-01-30'),
    freeMonthCount: 0,
    installmentPaymentCount: 0,
  },
  items: [],
  remainder: {
    amount: 0,
  },
});

export const getSubscriptionPlan: (p?: Partial<SubscriptionPlan>) => SubscriptionPlan = (
  p?: Partial<SubscriptionPlan>,
): SubscriptionPlan => ({
  ...getDefaultsSubscriptionPlan(),
  ...p,
});
