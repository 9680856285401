import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CockpitCategorySummary,
  CockpitPage,
  CockpitParameters,
  CockpitTargetParameters,
  TaskDepartment,
} from '@dougs/task/dto';
import { Collaborator, Team } from '@dougs/user/dto';
import { TaskHttpService } from '../../http/task.http';
import { buildTaskHttpParams, TaskParametersWithoutTarget } from './cockpit-build-params.state.util';
import { CockpitError } from './cockpit-error.state.util';

export type GetCockpitSummaryObservablFnParameters = CockpitTargetParameters &
  Pick<CockpitParameters, 'page' | 'partition'> &
  TaskParametersWithoutTarget;

export function getTasksSummaries(
  parameters: GetCockpitSummaryObservablFnParameters,
  taskHttpService: Readonly<TaskHttpService>,
): Observable<CockpitCategorySummary[]> {
  const { page, domain, collaborator, team, department } = parameters;
  const params: HttpParams = buildTaskHttpParams(parameters);

  if (domain === 'department') {
    return getDepartmentTasksSummaries(department, team, page, params, taskHttpService);
  }

  if (domain === 'team') {
    return getTeamTasksSummaries(team, collaborator, page, params, taskHttpService);
  }

  if (domain === 'collaborator') {
    return getCollaboratorTasksSummaries(collaborator, params, taskHttpService);
  }

  throw new CockpitError('Please ensure domain is valid while retrieving summaries.');
}

function getDepartmentTasksSummaries(
  department: TaskDepartment | null,
  team: Readonly<Team> | null,
  page: CockpitPage,
  httpParams: HttpParams,
  taskHttpService: Readonly<TaskHttpService>,
): Observable<CockpitCategorySummary[]> {
  if (!department) {
    throw new CockpitError('Department should have been set.');
  }

  if (team && ['to-do', 'completed'].includes(page)) {
    return taskHttpService.getTeamTasksSummaries(team.userId, httpParams.append('department', department));
  }

  return taskHttpService.getDepartmentTasksSummaries(department, httpParams);
}

function getTeamTasksSummaries(
  team: Readonly<Team> | null,
  collaborator: Readonly<Collaborator> | null,
  page: CockpitPage,
  httpParams: HttpParams,
  taskHttpService: Readonly<TaskHttpService>,
): Observable<CockpitCategorySummary[]> {
  if (!team) {
    throw new CockpitError('Team should have been set.');
  }

  if (collaborator && ['to-do', 'completed', 'delegated'].includes(page)) {
    return taskHttpService.getCollaboratorTasksSummaries(collaborator.id, httpParams.delete('assignStatus'));
  }

  return taskHttpService.getTeamTasksSummaries((team as Team).userId, httpParams);
}

function getCollaboratorTasksSummaries(
  collaborator: Readonly<Collaborator> | null,
  httpParams: HttpParams,
  taskHttpService: Readonly<TaskHttpService>,
): Observable<CockpitCategorySummary[]> {
  if (!collaborator) {
    throw new CockpitError('Collaborator should have been set.');
  }

  return taskHttpService.getCollaboratorTasksSummaries(collaborator.id, httpParams.delete('assignStatus'));
}
