import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Task } from '@dougs/task/dto';
import { AbstractTaskState } from './abstract-task.state';

@Injectable({
  providedIn: 'root',
})
export class ModalTaskStateService extends AbstractTaskState<{ modalTask: Task }> {
  readonly modalTask$: Observable<Task> = this.select((state) => state.modalTask);

  async refreshTasks(taskId: number, seen = false): Promise<void> {
    try {
      const task: Task | null = await this.getTask(taskId, seen);
      if (task) {
        this.setState({
          modalTask: task,
        });
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  protected handleTaskModification(task: Task): void {
    if (task.id === this.state?.modalTask?.id) {
      this.setState({
        modalTask: task,
      });
    }
  }
}
