import { Injectable } from '@angular/core';
import { HttpCacheService } from '@dougs/core/http-cache';
import { TaskTemplate } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class TemplateTaskCacheService extends HttpCacheService<TaskTemplate> {
  CACHE_DURATION_IN_SECONDS = 3600;
}
