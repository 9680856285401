import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Task } from '@dougs/task/dto';
import { AbstractTaskState } from './abstract-task.state';

@Injectable({
  providedIn: 'root',
})
export class ClosingTaskStateService extends AbstractTaskState<{ closingTask: Task | null }> {
  readonly closingTask$: Observable<Task | null> = this.select((state) => state.closingTask);

  async refreshTasks(taskId: number): Promise<Task | null> {
    try {
      const closingTask: Task = await lastValueFrom(this.taskHttpService.getTaskById(taskId));
      this.setState({
        closingTask,
      });
      return closingTask;
    } catch (e) {
      this.logger.error(e);
      this.setState({
        closingTask: null,
      });

      return null;
    }
  }

  protected handleTaskModification(task: Task): void {
    if (task.id === this.state?.closingTask?.id) {
      this.setState({
        closingTask: task,
      });
    }
  }

  protected removeTaskState(task: Task): void {
    if (task.id === this.state?.closingTask?.id) {
      this.setState({
        closingTask: null,
      });
    }
  }
}
