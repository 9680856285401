import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AbstractTaskState } from './abstract-task.state';

@Injectable({
  providedIn: 'root',
})
export class SupportTasksStateService extends AbstractTaskState<void> {
  async refreshTasks(
    targetFilters: string,
    completedFilters: string,
    search: string,
    companyId?: number,
  ): Promise<void> {
    try {
      const params: HttpParams = this.buildSearchParams(targetFilters, completedFilters, search, companyId);
      this.setState({
        tasks: await lastValueFrom(this.taskHttpService.getSupportTasks(params)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  private buildSearchParams(
    targetFilter: string,
    completedFilter: string,
    search: string,
    companyId?: number,
  ): HttpParams {
    let params: HttpParams = new HttpParams();
    this.activeFilters = [(task): boolean => task.code === 'tech:support'];
    params = this.buildTargetFilterParams(targetFilter, params, companyId);

    if (search) {
      params = params.append('search', search);
    }

    return this.buildCompletedFilterParams(completedFilter, params);
  }

  private buildTargetFilterParams(targetFilter: string, params: HttpParams, companyId?: number): HttpParams {
    params = params.append('targetFilter', targetFilter);
    if (targetFilter !== 'all' && companyId) {
      params = params.append('companyId', companyId);
      this.activeFilters.push((task) => task.metadata?.activeCompanyId === companyId);
    }

    return params;
  }

  private buildCompletedFilterParams(completedFilter: string, params: HttpParams): HttpParams {
    let newParams = params;

    if (completedFilter === 'actives') {
      newParams = newParams.append('statusFilter', 'activeOrNewActivity');
    }

    if (completedFilter === 'completed') {
      newParams = newParams.append('statusFilter', 'completed');
    }

    return newParams;
  }
}
