import { TaskESignProcedure } from '../dto';
import { getTaskMock } from './task.mock';

const getDefaults = (): TaskESignProcedure => ({
  task: getTaskMock(),
  procedure: '',
});

export const getTaskESignProcedureMock = (p?: Partial<TaskESignProcedure>): TaskESignProcedure => ({
  ...getDefaults(),
  ...p,
});
