import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { QualityControlRemark } from '@dougs/task/dto';

@Injectable({ providedIn: 'root' })
export class QualityControlRemarksHttpService {
  constructor(private readonly http: HttpClient) {}

  getQualityControlRemarks(taskId: number): Observable<QualityControlRemark[]> {
    return this.http.get<QualityControlRemark[]>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/qualityControl/remarks`,
    );
  }

  addQualityControlRemark(taskId: number, remarkBody: string): Observable<QualityControlRemark> {
    return this.http.post<QualityControlRemark>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/qualityControl/remarks`,
      { remark: remarkBody },
    );
  }

  deleteQualityControlRemark(taskId: number, remarkId: number): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/qualityControl/remarks/${remarkId}`,
    );
  }
}
