import { Injectable } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import { Comment, Task } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  addAttachmentToTask(task: Task, attachment: Attachment): Task {
    return {
      ...task,
      attachments: [...task.attachments, attachment],
    };
  }

  addAttachmentsToTask(task: Task, attachment: Attachment[]): Task {
    return {
      ...task,
      attachments: [...task.attachments, ...attachment],
    };
  }

  removeAttachmentToTask(task: Task, attachment: Attachment): Task {
    return {
      ...task,
      attachments: task.attachments.filter((attachmentIterated) => attachmentIterated.id !== attachment.id),
    };
  }

  addAttachmentsToCommentTask(task: Task, comment: Comment, attachments: Attachment[]): Task {
    return {
      ...task,
      comments: task.comments.map((commentIterated) =>
        commentIterated.id === comment.id
          ? {
              ...commentIterated,
              attachments: [...commentIterated.attachments, ...attachments],
            }
          : commentIterated,
      ),
    };
  }

  removeAttachmentToCommentTask(task: Task, attachment: Attachment): Task {
    return {
      ...task,
      comments: task.comments.map((commentIterated) =>
        commentIterated.id === attachment.modelId
          ? {
              ...commentIterated,
              attachments: commentIterated.attachments.filter(
                (attachmentIterated) => attachmentIterated.id !== attachment.id,
              ),
            }
          : commentIterated,
      ),
    };
  }
}
