import { TaskTemplateCategory } from '../..';

const getDefaults = (): TaskTemplateCategory => ({
  categoryCode: 'test-category',
  categoryTitle: 'Test',
  templates: [{ templateTitle: 'Test template', templateCode: 'test:template' }],
});

export const getTaskTemplateCategoryMock = (p?: Partial<TaskTemplateCategory>): TaskTemplateCategory => ({
  ...getDefaults(),
  ...p,
});
