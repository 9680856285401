export interface Subscription {
  hasStripeCustomerId?: boolean;
  isTrialing: boolean;
  trialRemainingDays: number;
  trialEndsAt?: Date;
  trialStartedAt?: Date;
  trialPeriodDays: number;
  plan?: string;
  activeTerms: SubscriptionActiveTerms;
  billingStartsAt: string | Date;
  isValid: boolean;
  isFreeForever: boolean;
  isCardExpired: boolean;
  isUnpaidSince?: Date;
  cardLast4: string;
  cardBrand: string;
  cardExpirationDate: string;
  isCardAlmostExpired: boolean;
  freeMonthCount: number;
  isAnnuallyPaid: boolean;
  nextBillingAt: Date;
  suspendedAt: string;
  suspended: boolean;
  periodEndsAt: Date | string;
  isActive: boolean;
  activatedAt: Date;
  adjustmentDiscountPercent: number;
  hasCard: boolean;
  hasAdjustment: boolean;
  hasRemainder: boolean;
  isAGOIncluded: boolean;
  quantity: number;
  paidToThirdPartyMonthCount: number;
  adjustmentInstallmentPaymentCount: number;
  planDescriptor: { name: string; amount: number };
  paymentMethod?: PaymentMethodTypes;
  isVATRemoteTransmissionIncluded?: boolean;
  // the below intends to replace the above
  isRemoteTransmissionIncluded?: boolean;
  periodStartsAt?: Date | string;
  customAmount: number | null;
  hasDedicatedAccountant: boolean;
}

export interface SubscriptionPlan {
  id: string;
  name: string;
  group: string;
  amount: number;
  description: string;
  branding: string;
  hasAdjustment: boolean;
  adjustment: {
    amount: number;
    startAt: Date;
    endAt: Date;
    dayCount: number;
    installmentPaymentCount: number;
    freeMonthCount: number;
    discountPercent: number;
  };
  items: {
    content: string;
    highlight: boolean;
    isVisible: boolean;
  }[];
  upcomingInvoices: GenericInvoiceData[];
  remainder: {
    amount: number;
  };
  minimumAmountByFiscalYear: number;
  isReferred: boolean;
  isActive: boolean;
  isAvailable: boolean;
  options?: {
    ecommerce?: {
      value?: string;
      amount?: number;
    };
  };
  ribbon?: string;
  isVATRemoteTransmissionIncluded?: boolean;
  // the below intends to replace the above
  isRemoteTransmissionIncluded?: boolean;
  hasCustomAmount?: boolean;
}

export interface SubscriptionActiveTerms {
  id: string;
  version: number;
  acceptedAt: string | Date;
  isDirty: boolean;
  companyData: {
    hasEmployee: boolean;
    taxRegime: string;
    legalForm: string;
    legalName: string;
    siren: string;
  };
  isGonnaChange: boolean;
}

export enum PaymentMethodTypes {
  CARD = 'card',
  PARTNER = 'partner',
  TRANSFER = 'transfer',
}

export interface GenericInvoiceData {
  id: number;
  date: Date | string;
  amount: number;
  amountExcludingTaxes: number;
  items: GenericInvoiceItemData[];
}

export interface GenericInvoiceItemData {
  id: number;
  fullDescription: string;
  totalAmount: number;
  options: unknown[];
  metadata: Record<string, unknown>;
}

export const PLAN_MULTI_EXCLU_PREFIX = 'exclusivité_multi';

export const SCI_PLAN_GROUP = 'Sci';

export const LMNP_PLAN_GROUP = 'Lmnp';
