import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { QualityControlRemark } from '@dougs/task/dto';
import { QualityControlRemarksHttpService } from '../../../http/quality-control-remarks/quality-control-remarks.http';

interface QualityControlRemarksState {
  qualityControlRemarks: QualityControlRemark[];
}

@Injectable({ providedIn: 'root' })
export class QualityControlRemarksStateService extends StateService<QualityControlRemarksState> {
  constructor(
    private readonly qualityControlRemarksHttpService: QualityControlRemarksHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  readonly remarks$: Observable<QualityControlRemark[]> = this.select((state) => state.qualityControlRemarks ?? []);
  readonly completedRemarks$: Observable<QualityControlRemark[]> = this.select(
    (state) => state.qualityControlRemarks?.filter((remark) => remark.isCompleted) ?? [],
  );
  readonly uncompletedRemarks$: Observable<QualityControlRemark[]> = this.select(
    (state) => state.qualityControlRemarks?.filter((remark) => !remark.isCompleted) ?? [],
  );

  async refreshQualityControlRemarks(taskId: number): Promise<void> {
    try {
      const qualityControlRemarks: QualityControlRemark[] = await toPromise(
        this.qualityControlRemarksHttpService.getQualityControlRemarks(taskId),
      );
      this.setState({ qualityControlRemarks });
    } catch (error) {
      this.logger.error(error);
      this.setState({ qualityControlRemarks: [] });
    }
  }

  async addQualityControlRemark(taskId: number, remarkBody: string): Promise<void> {
    try {
      const qualityControlRemark: QualityControlRemark = await toPromise(
        this.qualityControlRemarksHttpService.addQualityControlRemark(taskId, remarkBody),
      );
      this.setState({
        qualityControlRemarks: [...(this.state?.qualityControlRemarks ?? []), qualityControlRemark],
      });
    } catch (error) {
      this.logger.error(error);
    }
  }

  async deleteQualityControlRemark(taskId: number, remarkId: number): Promise<void> {
    try {
      await toPromise(this.qualityControlRemarksHttpService.deleteQualityControlRemark(taskId, remarkId));
      this.setState({
        qualityControlRemarks: (this.state?.qualityControlRemarks ?? []).filter((remark) => remark.id !== remarkId),
      });
    } catch (error) {
      this.logger.error(error);
    }
  }

  resetState(): void {
    this.setState({ qualityControlRemarks: [] });
  }
}
