export interface OrderByTask {
  key: TaskOrder;
  direction: 'ASC' | 'DESC' | '';
}

export enum TaskOrder {
  START_DATE = 'startDate',
  DUE_DATE = 'dueDate',
  COMPLETED_SUBTASK_COUNT = 'completedSubTaskCount',
  TRIAL_ENDS_AT = 'trialEndsAt',
  ACCOUNTING_SURVEY_COMPLETED_AT = 'accountingSurveyCompletedAt',
  FIRST_ACCOUNTING_CLOSING_DATE = 'firstAccountingClosingDate',
  ACTIVE_SUBTASK_TITLE = 'activeSubTaskTitle',
  FINANCIAL_RISK_LEVEL = 'financialRiskLevel',
  CUSTOMER_IMPACT_LEVEL = 'customerImpactLevel',
  LEGAL_STATE = 'legalState',
  EMPTY = '',
}
