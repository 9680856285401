import { FormControl } from '@angular/forms';
import { Profile } from '@dougs/user/dto';
import { TaskFormInlineGroupValue, VariableDescriptors } from '../task-form.dto';
import { Task } from '../task.dto';

export interface PayslipModalInfo {
  period: string;
  payslipInfos: PayslipInfo[];
  isEditable: boolean;
  variableDescriptors: VariableDescriptors;
}

export interface PayslipInfo {
  id: number;
  partnerId: number;
  profile: Profile;
  socialInfo: PayslipSocialInfos;
  validatedAt?: Date;
  status: PayslipInfoStatus;
}

export interface PayslipSocialInfos {
  salary: number;
  contract: string;
  contractEndDate?: string;
  personsInCharge: number;
  hasCooperative: boolean;
  isNet: boolean;
}

export interface Payslip {
  id: number;
  period: string;
  companyId: number;
  partnerId: number;
  customerVariables: CustomerVariable;
  collaboratorVariables: CollaboratorVariable[];
  validatedAt?: string;
}

export interface CustomerVariable {
  variables: TaskFormInlineGroupValue;
  customerTask: Task;
}

export interface CollaboratorVariable {
  type: CollaboratorVariableType;
  code: string;
  description: string;
  isNative?: boolean;
  isCustom?: boolean;
  date?: string; // -> si isOneDay pour les absences
  amount?: number;
  isNet?: boolean;
  label?: string;
  hours?: number;
  isOneDay?: boolean;
  fromDate?: string; // -> si !isOneDay
  toDate?: string; // -> si !isOneDay
  totalHours?: number; // -> si isOneDay
  extraHours?: number; // -> si isOneDay
  customHours?: number; // -> si isOneDay
}

export interface CollaboratorVariableFormGroup {
  type: FormControl<CollaboratorVariableType>;
  code: FormControl<string | undefined>;
  description: FormControl<string>;
  isNative?: FormControl<boolean>;
  isCustom?: FormControl<boolean>;
  date?: FormControl<string>;
  amount?: FormControl<number | undefined>;
  isNet?: FormControl<boolean>;
  label?: FormControl<string>;
  hours?: FormControl<number | undefined>;
  isOneDay?: FormControl<boolean>;
  fromDate?: FormControl<string>;
  toDate?: FormControl<string>;
  totalHours?: FormControl<number | undefined>;
  extraHours?: FormControl<number | undefined>;
  customHours?: FormControl<number | undefined>;
}

export enum PayslipInfoStatus {
  NOT_STARTED = 'not-started',
  STARTED = 'started',
  COMPLETED = 'completed',
}

export enum CollaboratorVariableType {
  HOUR = 'hour',
  PRIME = 'prime',
  ABSENCE = 'absence',
  DEPOSIT = 'deposit',
  UNKNOWN = 'unknown',
}
