import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { AssigneeStats } from '@dougs/task/dto';
import { TasksStatsHttpService } from '../../../http/tasks-stats.http';

interface AssigneeStatsState {
  me: AssigneeStats[];
  teams: Record<string, AssigneeStats[]>;
}

@Injectable({
  providedIn: 'root',
})
export class AssigneeStatsStateService extends StateService<AssigneeStatsState> {
  constructor(
    private readonly tasksStatsHttpService: TasksStatsHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  readonly meStats$: Observable<AssigneeStats[]> = this.select((state) => state.me);
  readonly teamsStats$: Observable<Record<string, AssigneeStats[]>> = this.select((state) => state.teams);

  async refreshMeAssigneeStats(assigneeId: number, params: Params): Promise<void> {
    try {
      const meAssigneeStats: AssigneeStats[] = await toPromise(
        this.tasksStatsHttpService.getAssigneeStats(assigneeId, {
          isDelegated: params.isDelegated,
          filter: params.taskStatus,
          q: params.q,
        }),
      );
      this.setState({
        me: meAssigneeStats,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshTeamStats(teamId: number, params: Params): Promise<void> {
    try {
      const teamStats: AssigneeStats[] = await toPromise(
        this.tasksStatsHttpService.getTeamStats(teamId, {
          isDelegated: params.isDelegated,
          filter: params.taskStatus,
          q: params.q,
        }),
      );
      this.setState({
        teams: {
          ...this.state?.teams,
          [teamId]: teamStats,
        },
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshDepartmentStats(departmentCode: string, params: Params, skipUserStats = true): Promise<void> {
    try {
      const departmentStats: AssigneeStats[] = await toPromise(
        this.tasksStatsHttpService.getDepartmentStats(
          departmentCode,
          {
            isDelegated: params.isDelegated,
            filter: params.taskStatus,
            q: params.q,
          },
          skipUserStats,
        ),
      );
      this.setState({
        teams: {
          ...this.state?.teams,
          [departmentCode]: departmentStats,
        },
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
