import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { Activity, Comment } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class ActivityHttpService {
  constructor(private readonly http: HttpClient) {}

  getActivities(companyId: number, onlyPermanent: boolean): Observable<Activity[]> {
    return this.http.get<Activity[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/activities?onlyPermanent=${onlyPermanent}`,
    );
  }

  updateCommentActivity(companyId: number, comment: Comment): Observable<Comment> {
    return this.http.post<Comment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/comments/${comment.id}`,
      comment,
    );
  }

  uploadCommentAttachment(companyId: number, commentId: number, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/comments/${commentId}/attachments?filename=${file.name}&type=attachment`,
      formData,
    );
  }

  deleteCommentAttachment(companyId: number, attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/comments/${attachment.modelId}/attachments/${attachment.id}`,
    );
  }
}
