import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isBefore,
  isSameDay,
  isWithinInterval,
  startOfDay,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns';
import { lastValueFrom, Observable } from 'rxjs';
import { FilterTask, OrderByTask, Task, TASK_STATUS, TaskOrder } from '@dougs/task/dto';
import { AbstractTaskState } from './abstract-task.state';

@Injectable({
  providedIn: 'root',
})
export class TasksStateService extends AbstractTaskState<void> {
  private searchParams: HttpParams = new HttpParams();
  private readonly pageSize = 30;

  readonly tasks$: Observable<Task[]> = this.select((state) => state.tasks);

  async refreshTasks(queryParams: Params, orderBy: OrderByTask, filters: FilterTask): Promise<void> {
    try {
      this.searchParams = this.buildParams(queryParams, orderBy, filters);

      this.setState({
        tasks: await lastValueFrom(this.taskHttpService.getTasksV2(this.searchParams)),
      });
    } catch (e) {
      this.logger.error(e);
      this.setState({ tasks: [] });
    }
  }

  async loadMoreTasks(orderBy: OrderByTask): Promise<void> {
    try {
      const lastTask: Task | undefined = this.state.tasks[this.state.tasks?.length - 1];
      const orderByKey: TaskOrder = orderBy.key;
      const lastTaskId: number | undefined = lastTask?.id;
      const lastTaskOrderValue: string | undefined = this.getTaskOrderByValue(lastTask, orderByKey);
      if (lastTask) {
        this.setState({
          tasks: [
            ...this.state.tasks,
            ...(await lastValueFrom(
              this.taskHttpService.getTasksV2(
                this.searchParams,
                this.pageSize,
                lastTaskId,
                orderByKey,
                lastTaskOrderValue,
              ),
            )),
          ],
        });
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  private buildParams(queryParams: Params, orderBy: OrderByTask, filters: FilterTask): HttpParams {
    this.activeFilters = [];
    let params: HttpParams = new HttpParams();
    const paramsObject = {
      ...this.buildOrderByParams(orderBy),
      ...this.buildCategoryParams(queryParams),
      ...this.buildCodeParams(queryParams),
      ...this.buildAssigneeIdParams(queryParams),
      ...this.buildDepartmentParams(queryParams),
      ...this.buildIsPriorityParams(queryParams),
      ...this.buildHasMentionParams(queryParams),
      ...this.buildTaskStatusParams(queryParams),
      ...this.buildIsDelegatedParams(queryParams),
      ...this.buildSearchParams(queryParams),
      ...this.buildClosingDateFilter(queryParams, filters),
      ...this.buildActiveSubTaskCodeFilter(filters),
      ...this.buildLegalFormsFilter(filters),
      ...this.buildWithN1Filter(queryParams, filters),
      ...this.buildTaxRegimeFilter(filters),
      ...this.buildVatFilter(filters),
      ...this.buildLegalStateFilter(filters),
    };

    params = params.appendAll(paramsObject);

    return params;
  }

  private buildOrderByParams(orderBy: OrderByTask): {
    sortBy?: string;
    sortDirection?: string;
  } {
    if (orderBy.key && orderBy.direction) {
      return {
        sortBy: orderBy.key,
        sortDirection: orderBy.direction,
      };
    }

    return {};
  }

  private buildWithN1Filter(queryParams: Params, filters: FilterTask): Record<string, boolean> | null {
    if (queryParams.code === 'activation' && filters.withN1 !== null) {
      this.activeFilters.push((task) => task.metadata.isCompanyFirstAccountingYear === !filters.withN1);
      return {
        withN1: filters.withN1,
      };
    }

    return null;
  }

  private buildLegalFormsFilter(filters: FilterTask): Record<string, string> | null {
    if (filters.legalForms.length) {
      this.activeFilters.push(
        (task) =>
          !!task.metadata.companyConfig?.legalForm &&
          filters.legalForms.includes(task.metadata.companyConfig.legalForm),
      );
      return {
        legalForm: JSON.stringify([...(filters.legalForms || [])]),
      };
    }

    return null;
  }

  private buildTaxRegimeFilter(filters: FilterTask): Record<string, string> | null {
    if (filters.taxRegime) {
      this.activeFilters.push((task) => task.metadata.companyConfig?.taxRegime === filters.taxRegime);
      return {
        taxRegime: filters.taxRegime,
      };
    }

    return null;
  }

  private buildVatFilter(filters: FilterTask): Record<string, string> | null {
    if (filters.vat) {
      this.activeFilters.push((task) => task.metadata.companyConfig?.vatType === filters.vat);
      return {
        vatType: filters.vat,
      };
    }

    return null;
  }

  private buildLegalStateFilter(filters: FilterTask): Record<string, string> | null {
    if (filters.legalState) {
      this.activeFilters.push((task) => task?.legalState === filters.legalState);
      return {
        legalState: filters.legalState,
      };
    }

    return null;
  }

  private buildCodeParams(queryParams: Params): Record<string, string> {
    if (queryParams.code) {
      let codeParams = {};
      codeParams = {
        code: queryParams.code,
      };

      this.activeFilters.push((task) => task.code === queryParams.code);

      return codeParams;
    }

    this.activeFilters.push((task) => task.code !== 'declaration');

    return {};
  }

  private buildDepartmentParams(queryParams: Params): Record<string, string> {
    // department is added to url for assignee in department without teams but shouldn't be in task request
    if (queryParams.department && (!queryParams.assigneeId || queryParams.assigneeId === 'unassigned')) {
      this.activeFilters.push((task) => task.department === queryParams.department);

      return {
        department: queryParams.department,
      };
    }

    this.activeFilters.push((task) => task.department !== 'customer');

    return {};
  }

  private buildCategoryParams(queryParams: Params): Record<string, string> {
    if (queryParams.category) {
      this.activeFilters.push((task) => task.category === queryParams.category);
      return {
        category: queryParams.category,
      };
    }

    return {};
  }

  private buildIsPriorityParams(queryParams: Params): Record<string, boolean> {
    if (queryParams.isPriority) {
      this.activeFilters.push((task) => task.isPriority);
      return {
        isPriority: true,
      };
    }

    return {};
  }

  private buildHasMentionParams(queryParams: Params): Record<string, boolean> {
    if (queryParams.hasMention) {
      this.activeFilters.push((task) => !!task.mention);
      return {
        hasMention: true,
      };
    }

    return {};
  }

  private buildAssigneeIdParams(queryParams: Params): Record<string, string> {
    if (queryParams.assigneeId) {
      if (queryParams.assigneeId === 'unassigned') {
        this.activeFilters.push((task) => !task.assigneeId);

        return {
          assignStatus: 'collaborator-unassigned',
        };
      } else if (queryParams.asTeam) {
        this.activeFilters.push(
          (task) =>
            task.assigneeId === Number(queryParams.assigneeId) ||
            task.assignee?.teamId === Number(queryParams.assigneeId),
        );

        return {
          teamUserId: queryParams.assigneeId,
          assignStatus: 'team-assigned',
        };
      }
      this.activeFilters.push((task) => task.assigneeId === Number(queryParams.assigneeId));

      return queryParams.toAssign
        ? {
            teamUserId: queryParams.assigneeId,
            assignStatus: 'collaborator-unassigned',
          }
        : {
            assigneeId: queryParams.assigneeId,
          };
    }

    return {};
  }

  private buildIsDelegatedParams(queryParams: Params): Record<string, boolean> {
    if (queryParams.isDelegated) {
      this.activeFilters.push((task) => task.status === TASK_STATUS.DELEGATED);
    } else {
      this.activeFilters.push((task) => task.status !== TASK_STATUS.DELEGATED);
    }

    return {
      isDelegated: queryParams.isDelegated ? queryParams.isDelegated : false,
    };
  }

  private buildTaskStatusParams(queryParams: Params): Record<string, string> {
    if (queryParams.taskStatus) {
      this.buildTaskStatusActiveFilters(queryParams.taskStatus);
      return {
        taskScopeFilter: queryParams.taskStatus,
      };
    }

    return {};
  }

  private buildSearchParams(queryParams: Params): Record<string, string> {
    if (queryParams.q) {
      return {
        search: queryParams.q,
      };
    }

    return {};
  }

  private buildClosingDateFilter(queryParams: Params, filters: FilterTask): Record<string, string> {
    if (
      (queryParams.code === 'activation' ||
        queryParams.code === 'activationPreviousAccountantInformation' ||
        queryParams.code === 'accounting:previousAccountantStateIntegration') &&
      filters.closingDate
    ) {
      const endOfMonthDate: Date = endOfMonth(addMonths(new Date(), Number(filters.closingDate)));
      this.activeFilters.push(
        (task) =>
          !!task.metadata.firstAccountingClosingDate &&
          isSameDay(new Date(task.metadata.firstAccountingClosingDate), endOfMonthDate) &&
          isBefore(new Date(task.metadata.firstAccountingClosingDate), endOfMonthDate),
      );
      return {
        filterClosingDate: filters.closingDate,
      };
    }

    return {};
  }

  private buildActiveSubTaskCodeFilter(filters: FilterTask): Record<string, string> {
    if (filters.activeSubTaskCode) {
      this.activeFilters.push((task) => task.activeSubTaskCode === filters.activeSubTaskCode);
      return {
        activeSubTaskCode: filters.activeSubTaskCode,
      };
    }

    return {};
  }

  private buildTaskStatusActiveFilters(taskStatus: string): void {
    switch (taskStatus) {
      case 'today':
        this.activeFilters.push(
          (task) =>
            !task.abortedAt &&
            !task.completedAt &&
            (isSameDay(new Date(task.startDate), this.todayInEuropeParis()) ||
              isBefore(new Date(task.startDate), this.todayInEuropeParis())),
        );
        break;
      case 'overdue':
        this.activeFilters.push(
          (task) => !task.completedAt && isBefore(new Date(task.dueDate), this.todayInEuropeParis()),
        );
        break;
      case 'isPriority':
        this.activeFilters.push((task) => task.isPriority);
        break;
      case 'week':
        this.activeFilters.push((task) => {
          if (isBefore(new Date(task.dueDate), this.todayInEuropeParis())) {
            return false;
          }

          return (
            !task.completedAt &&
            isWithinInterval(new Date(task.startDate), {
              start: startOfWeek(this.todayInEuropeParis(), {
                weekStartsOn: 1,
              }),
              end: endOfWeek(this.todayInEuropeParis(), {
                weekStartsOn: 1,
              }),
            })
          );
        });
        break;
      case 'toComeIn30days':
        this.activeFilters.push(
          (task) =>
            !task.completedAt &&
            !task.onHold &&
            isWithinInterval(new Date(task.startDate), {
              start: addDays(this.todayInEuropeParis(), 1),
              end: addMonths(this.todayInEuropeParis(), 1),
            }),
        );
        break;
      case 'actives':
        this.activeFilters.push((task) => !task.completedAt);
        break;
      case 'completed':
        this.activeFilters.push((task) => !!task.completedAt);
        break;
      case 'completed3daysAgo':
        this.activeFilters.push(
          (task) =>
            !!task.completedAt &&
            isWithinInterval(new Date(task.completedAt), {
              start: startOfDay(subDays(this.todayInEuropeParis(), 3)),
              end: endOfDay(this.todayInEuropeParis()),
            }),
        );
        break;
      case 'completed7daysAgo':
        this.activeFilters.push(
          (task) =>
            !!task.completedAt &&
            isWithinInterval(new Date(task.completedAt), {
              start: startOfDay(subDays(this.todayInEuropeParis(), 7)),
              end: endOfDay(this.todayInEuropeParis()),
            }),
        );
        break;
      case 'completed30daysAgo':
        this.activeFilters.push(
          (task) =>
            !!task.completedAt &&
            isWithinInterval(new Date(task.completedAt), {
              start: startOfDay(subMonths(this.todayInEuropeParis(), 1)),
              end: endOfDay(this.todayInEuropeParis()),
            }),
        );
        break;
      case 'aborted':
        this.activeFilters.push((task) => !task.onHold && !!task.abortedAt && !task.deletedAt);
        break;
      case 'project':
        this.activeFilters.push((task) => task.quoteStatus === 'project');
        break;
    }
  }

  // TODO Méthode générique avec le TaskComponentService pour le task.component.ts
  private getTaskOrderByValue(task: Task, orderByKey: TaskOrder): string | undefined {
    if (task && orderByKey) {
      switch (orderByKey) {
        case TaskOrder.START_DATE:
          return task.startDate.toString();
        case TaskOrder.DUE_DATE:
          return task.dueDate.toString();
        case TaskOrder.TRIAL_ENDS_AT:
          return task.target?.company?.subscription?.trialEndsAt?.toString();
        case TaskOrder.FIRST_ACCOUNTING_CLOSING_DATE:
          return task.metadata?.firstAccountingClosingDate?.toString();
        case TaskOrder.ACCOUNTING_SURVEY_COMPLETED_AT:
          return task.metadata?.surveyCompletedAt?.toString();
        case TaskOrder.ACTIVE_SUBTASK_TITLE:
          return task?.activeSubTaskTitle;
        case TaskOrder.CUSTOMER_IMPACT_LEVEL:
          return task.formData?.customerImpactLevel;
        case TaskOrder.FINANCIAL_RISK_LEVEL:
          return task.formData?.financialRiskLevel;
        case TaskOrder.COMPLETED_SUBTASK_COUNT:
          return task.completedSubTaskCount && task.subTaskCount ? task.completedSubTaskCount.toString() : '0';
        case TaskOrder.LEGAL_STATE:
          return task?.legalState || undefined;
      }
    }
    return undefined;
  }

  private todayInEuropeParis(): Date {
    return new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }));
  }
}
