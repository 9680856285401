export enum TASK_LEGAL_STATE {
  TO_PRODUCE = `toProduce`,
  TO_REGISTER = `toRegister`,
  WAITING_CLIENT_MEETING = 'waitingForClientMeeting',
  WAITING_CLIENT_BENEFITS = 'waitingForClientBenefits',
  WAITING_PROJECT_VALIDATION = 'waitingForProjectValidation',
  WAITING_CLIENT_SIGNATURE = 'waitingForClientSignature',
  WAITING_CLIENT_CAPITAL_DEPOSIT = 'waitingForClientCapitalDeposit',
  WAITING_TAX_REGISTRATION = 'waitingForTaxRegistration',
  WAITING_COMPANY_REGISTRATION = 'waitingForCompanyRegistration',
  WAITING_BALANCE_SHEET = 'waitingForBalanceSheet',
  PENDING_QUOTE = `pendingQuote`,
  WAITING_FOR_INFORMATION = `waitingForInformation`,
}
