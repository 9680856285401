import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addMonths, isAfter, isBefore } from 'date-fns';
import { lastValueFrom } from 'rxjs';
import { Task, TASK_STATUS, TASK_STATUS_FILTER, TaskScope } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { AbstractTaskState } from './abstract-task.state';

@Injectable({
  providedIn: 'root',
})
export class ControlPanelTasksStateService extends AbstractTaskState<void> {
  private searchParams: HttpParams = new HttpParams();

  constructor(private readonly userStateService: UserStateService) {
    super();
  }

  async refreshTasks(
    companyId: number,
    taskScope: TaskScope = TaskScope.ALL_BUT_CUSTOMER,
    statusFilter: TASK_STATUS_FILTER = TASK_STATUS_FILTER.ACTIVE_OR_DELEGATED,
    search?: string,
  ): Promise<void> {
    try {
      this.buildSearchParams(companyId, taskScope, statusFilter, search);
      this.setState({
        tasks: await lastValueFrom(this.taskHttpService.getControlPanelTasks(this.searchParams)),
      });
    } catch (e) {
      this.setState({ tasks: [] });
      this.logger.error(e);
    }
  }

  async loadMoreTasks(): Promise<void> {
    try {
      const lastTask: Task | undefined = this.state.tasks[this.state.tasks?.length - 1];
      const lastTaskId: number | undefined = lastTask?.id;
      this.setState({
        tasks: [
          ...this.state.tasks,
          ...(await lastValueFrom(this.taskHttpService.getControlPanelTasks(this.searchParams, lastTaskId))),
        ],
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  private buildSearchParams(
    companyId: number,
    taskScope: TaskScope,
    statusFilter: TASK_STATUS_FILTER,
    search?: string,
  ): void {
    this.activeFilters = [];
    this.searchParams = new HttpParams();

    this.activeFilters.push((task) => task.companyId === companyId);
    this.activeFilters.push((task) => task.code !== 'declaration' && task.code !== 'tech:support');

    if (search) {
      this.searchParams = this.searchParams.append('search', search);
    }

    this.searchParams = this.searchParams.append('companyId', companyId);

    if (taskScope === TaskScope.ALL_BUT_CUSTOMER) {
      this.activeFilters.push((task) => task.department !== 'customer');
    } else if (taskScope === TaskScope.OWN) {
      this.activeFilters.push((task) => task.assigneeId === this.userStateService.loggedInUser.id);
    } else {
      this.activeFilters.push((task) => task.department === taskScope);
    }
    this.searchParams = this.searchParams.append('taskScope', taskScope);

    switch (statusFilter) {
      case TASK_STATUS_FILTER.ACTIVE_OR_DELEGATED:
        this.activeFilters.push(
          (task) => task.completedAt === null && task.abortedAt === null && task.deletedAt === null,
        );
        break;
      case TASK_STATUS_FILTER.OVERDUE:
        this.activeFilters.push(
          (task) =>
            task.completedAt === null &&
            task.abortedAt === null &&
            task.deletedAt === null &&
            isBefore(new Date(task.dueDate), new Date()),
        );
        break;
      case TASK_STATUS_FILTER.COMPLETED:
        this.activeFilters.push((task) => task.status === TASK_STATUS.COMPLETED);
        break;
      case TASK_STATUS_FILTER.ABORTED:
        this.activeFilters.push((task) => task.status === TASK_STATUS.ABORTED);
        break;
      case TASK_STATUS_FILTER.FORECAST_IN_10_MONTHS:
        this.activeFilters.push(
          (task) =>
            task.completedAt === null &&
            task.abortedAt === null &&
            task.deletedAt === null &&
            !task.onHold &&
            isAfter(new Date(task.enabledAt), new Date()) &&
            isBefore(new Date(task.enabledAt), addMonths(new Date(), 10)),
        );
        break;
    }
    this.searchParams = this.searchParams.append('statusFilter', statusFilter);
  }

  protected addTaskState(task: Task): void {
    if (!this.taskExistInState(task)) {
      const newTasks: Task[] = this.state.tasks?.length ? [task, ...this.state.tasks] : [task];
      this.setState({
        tasks: newTasks,
      });
    }
  }
}
