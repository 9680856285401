import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { CategoryStats } from '@dougs/task/dto';
import { TasksStatsHttpService } from '../../../http/tasks-stats.http';

interface CategoryStatsState {
  categoryStats: CategoryStats;
}

@Injectable({
  providedIn: 'root',
})
export class CategoryStatsStateService extends StateService<CategoryStatsState> {
  constructor(
    private readonly tasksStatsHttpService: TasksStatsHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  readonly categoryStats$: Observable<CategoryStats> = this.select((state) => state.categoryStats);

  async refreshCategoryStats(queryParams: Params): Promise<void> {
    try {
      const params: Record<string, string> = this.getParamsFromQueryParams(queryParams);
      const categoryStats: CategoryStats = await toPromise(this.tasksStatsHttpService.getCategoryStats(params));
      this.setState({
        categoryStats,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  private getParamsFromQueryParams(queryParams: Params): Record<string, string> {
    const qParams: Record<string, string> = { ...queryParams };
    const params: Record<string, string> = {};
    if (qParams.asTeam && qParams.assigneeId) {
      const teamId: string = qParams.assigneeId;
      delete qParams.asTeam;
      delete qParams.assigneeId;
      params['teamId'] = teamId;
    }

    if (qParams.assigneeId === 'unassigned') {
      qParams.assigneeId = 'null';
    }

    if (
      qParams.department === 'all' ||
      (qParams.department &&
        !!qParams.assigneeId &&
        qParams.assigneeId !== 'unassigned' &&
        qParams.assigneeId !== 'null')
    ) {
      delete qParams.department;
    }

    const taskStatus: string = qParams.taskStatus;

    if (taskStatus) {
      delete qParams.taskStatus;
      qParams.filter = taskStatus;
    }

    return { ...params, ...qParams };
  }
}
