import {
  CockpitAccountingTeamStats,
  CockpitDomain,
  CockpitFilter,
  CockpitPage,
  CockpitPartition,
  CockpitQuoteStatus,
  CockpitSort,
  CockpitTask,
  CockpitTaskDisplayOptions,
  CockpitUnseenStats,
  CurrentTasksPageRegister,
  TaskDepartment,
} from '@dougs/task/dto';
import { Collaborator, Team } from '@dougs/user/dto';

export const COCKPIT_DEFAULT_CATEGORY: string | null = null;

export const COCKPIT_DEFAULT_CODE: string | null = null;

export const COCKPIT_DEFAULT_COLLABORATOR: Readonly<Collaborator> | null = null;

export const COCKPIT_DEFAULT_DEPARTMENT: TaskDepartment | null = null;

export const COCKPIT_DEFAULT_DOMAIN: CockpitDomain = 'collaborator';

export const COCKPIT_DEFAULT_FILTER: Readonly<CockpitFilter> = Object.freeze({
  isLate: false,
  legalForm: null,
  isPriority: false,
  taxRegime: null,
  vatType: null,
  withDouggieMentions: false,
});

export const COCKPIT_DEFAULT_PAGE: CockpitPage = 'to-do';

export const COCKPIT_DEFAULT_QUOTE_STATUS: CockpitQuoteStatus = 'project';

export const COCKPIT_DEFAULT_SORT: CockpitSort = 'start-date';

export const COCKPIT_DEFAULT_PARTITION: Readonly<CockpitPartition> | null = null;

export const COCKPIT_DEFAULT_TASKS: ReadonlyMap<number, Readonly<CockpitTask>> = new Map<
  number,
  Readonly<CockpitTask>
>();

export const COCKPIT_DEFAULT_TEAM: Readonly<Team> | null = null;

export const COCKPIT_DEFAULT_UNSEEN_STATS: Readonly<CockpitUnseenStats> = Object.freeze({ mentions: 0, tasks: 0 });

export const COCKPIT_DEFAULT_LAST_BLOCK_METADATA: CurrentTasksPageRegister | null = null;

export const COCKPIT_DEFAULT_TASK_DISPLAY_OPTIONS: Readonly<CockpitTaskDisplayOptions> = Object.freeze({
  isAssignable: false,
  isPriority: false,
  isSelectable: false,
  showAssignee: false,
  showCompletedAt: false,
  showCompletedBy: false,
  showLatestComment: false,
  showProgression: false,
  showQuote: false,
  showStartsAt: false,
  showWorkload: false,

  removePadding: false,
  isMention: false,
});

export const COCKPIT_DEFAULT_ACCOUNTING_TEAM_STATS: Readonly<CockpitAccountingTeamStats> =
  Object.freeze<CockpitAccountingTeamStats>({
    isCompletedStats: false,
    activity: {
      points: 0,
      priorityTasks: 0,
      balanceSheets: 0,
    },
    collaborators: [],
  });
