import { CockpitCollaboratorStats } from '../dto';

const getDefaults = (): CockpitCollaboratorStats => ({
  userId: 1,
  collaboratorId: 1,

  tasksTotal: { count: 50, workload: 50 },
  tasksCompletedThisWeek: { count: 10, workload: 10 },
  tasksTodo: { count: 20, workload: 20 },
  tasksRemaining: { count: 20, workload: 20 },

  appointmentsTodo: { count: 10, workload: 10 },
  appointmentsCompletedThisWeek: { count: 5, workload: 5 },

  intercom: {
    closed: 0,
    snoozed: 0,
    open: 0,
    openUpdatedAt: null,
  },
});

export const getCockpitCollaboratorStatsMock = (p?: Partial<CockpitCollaboratorStats>): CockpitCollaboratorStats => ({
  ...getDefaults(),
  ...p,
});
