import { CockpitAccountingTeamStats } from '../dto';

const getDefaults = (): CockpitAccountingTeamStats => ({
  isCompletedStats: false,
  activity: {
    points: 0,
    priorityTasks: 0,
    balanceSheets: 0,
  },
  collaborators: [
    {
      id: 1,
      userId: 1,
      profile: {
        fullName: 'fullName',
        avatarUrl: 'avatar',
      },
      activity: {
        priorityTasks: 1,
        balanceSheets: 1,
        pointsAssignedThisWeek: 1,
        pointsAssignedBeforeThisWeek: 1,
        pointsCompletedThisWeek: 1,
        averageProductivity: 1,
      },
      balanceSheets: {
        accountingSurveyCompleted: 1,
        prepared: 1,
        accountingSurveyVerified: 1,
        revisionCompleted: 1,
      },
    },
  ],
});

export const getCockpitAccountingTeamStats = (p?: Partial<CockpitAccountingTeamStats>): CockpitAccountingTeamStats => ({
  ...getDefaults(),
  ...p,
});
