import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { AssigneeStats, CategoryStats } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class TasksStatsHttpService {
  constructor(private readonly http: HttpClient) {}

  getAssigneeStats(
    assigneeId: number,
    parameters: { q?: string; filter?: string; isDelegated?: boolean },
  ): Observable<AssigneeStats[]> {
    let params: HttpParams = new HttpParams();
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        params = params.append(key, value);
      }
    }
    return this.http.get<AssigneeStats[]>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/stats/assignee/${assigneeId}`,
      { params },
    );
  }

  getTeamStats(
    teamId: number,
    parameters: { q?: string; filter?: string; isDelegated?: boolean },
  ): Observable<AssigneeStats[]> {
    let params: HttpParams = new HttpParams();
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        params = params.append(key, value);
      }
    }
    return this.http.get<AssigneeStats[]>(`${AppConfig.settings.legacyApiServerUrl}/tasks/stats/team/${teamId}`, {
      params,
    });
  }

  getDepartmentStats(
    departmentCode: string,
    parameters: { q?: string; filter?: string; isDelegated?: boolean },
    skipUserStats: boolean,
  ): Observable<AssigneeStats[]> {
    let params: HttpParams = new HttpParams();
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        params = params.append(key, value);
      }
    }
    params = params.append('skipUserStats', skipUserStats);
    return this.http.get<AssigneeStats[]>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/stats/department/${departmentCode}`,
      {
        params,
      },
    );
  }

  getCategoryStats(parameters: Record<string, string>): Observable<CategoryStats> {
    let params: HttpParams = new HttpParams();
    for (const [key, value] of Object.entries(parameters)) {
      params = params.append(key, value);
    }
    return this.http.get<CategoryStats>(`${AppConfig.settings.legacyApiServerUrl}/tasks/stats/groups`, {
      params,
    });
  }
}
