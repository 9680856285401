import { getUserMock } from '@dougs/user/dto';
import { Comment } from '../dto';

const getDefaults = (): Comment => ({
  id: 1,
  createdAt: new Date('2023-04-19'),
  attachments: [],
  body: '',
  commenter: getUserMock(),
  commenterId: 1,
  companyId: 1,
  modelId: 1,
  modelName: '',
  tags: [],
  updatedAt: new Date('2023-04-19'),
  metadata: {
    isActivity: false,
    autogenerated: true,
  },
});

export const getCommentMock = (p?: Partial<Comment>): Comment => ({
  ...getDefaults(),
  ...p,
});
