import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { Task } from '@dougs/task/dto';
import { AbstractTaskState } from './abstract-task.state';

@Injectable({
  providedIn: 'root',
})
export class UserTasksStateService extends AbstractTaskState<void> {
  protected activeFilters = [(task: Task): boolean => task.completedAt === null];

  async refreshTasks(company: Company): Promise<void> {
    try {
      this.setState({
        tasks: await lastValueFrom(this.taskHttpService.getUserNotificationTasks(company.ownerId, company.id)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getUserTasks(company: Company, taskCode?: string): Promise<Task[]> {
    try {
      return await lastValueFrom(this.taskHttpService.getUserTasks(company.ownerId, company.id, taskCode));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  async markUserTasksAsSeen(userId: number, tasks: Task[]): Promise<boolean> {
    try {
      await lastValueFrom(this.taskHttpService.markUserTasksAsSeen(userId, tasks));

      for (const task of tasks) {
        this.tasksEventsService.propagateUpdateTask({
          ...task,
          unseen: false,
        });
      }

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async getUnseenUserTasks(company: Company): Promise<Task[]> {
    try {
      return await lastValueFrom(this.taskHttpService.getCustomerTasks(company.id, [], true));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  protected handleTaskModification(task: Task): void {
    const taskExistInState: boolean = this.taskExistInState(task);
    const isInFilter: boolean = this.isInFilter(task);

    if (taskExistInState) {
      if (isInFilter) {
        this.updateTaskState(task);
      } else {
        this.removeTaskState(task);
      }
    }
  }
}
