import { CreatableTaskTemplate } from '../dto';

const getDefaults = (): CreatableTaskTemplate => ({
  code: '',
  label: '',
  remarks: '',
});

export const getCreatableTaskTemplateMock = (p?: Partial<CreatableTaskTemplate>): CreatableTaskTemplate => ({
  ...getDefaults(),
  ...p,
});
