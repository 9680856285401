import { HttpParams } from '@angular/common/http';
import {
  CockpitFilter,
  CockpitPage,
  CockpitParameters,
  CockpitPartition,
  CockpitQuoteStatus,
  CockpitSort,
  CurrentTasksPageData,
} from '@dougs/task/dto';

export type TaskParametersWithoutTarget = Partial<
  Pick<CockpitParameters, 'page' | 'filter' | 'category' | 'code' | 'sort' | 'partition' | 'quoteStatus'>
>;

export function buildTaskHttpParams(
  parameters: TaskParametersWithoutTarget,
  currentTaskPage?: Readonly<CurrentTasksPageData> | null,
): HttpParams {
  const { page, filter, category, code, sort, partition, quoteStatus } = parameters;
  let httpParams = new HttpParams();

  if (page === 'mentions' || page === 'unseen') {
    return setTaskSortParams(httpParams, currentTaskPage, page);
  }

  if (filter) {
    httpParams = buildTaskFilterParams(httpParams, filter);
  }

  if (category) {
    httpParams = httpParams.append('category', category);
  }

  if (code) {
    httpParams = httpParams.append('code', code).delete('category');
  }

  if (page) {
    httpParams = buildTaskPageParams(httpParams, page, quoteStatus);
  }

  if (page && sort) {
    httpParams = setTaskSortParams(httpParams, currentTaskPage, page, sort);
  }

  if (partition) {
    httpParams = setTaskPartitionParams(httpParams, partition);
  }

  return httpParams;
}

function buildTaskFilterParams(httpParams: HttpParams, filter: Readonly<CockpitFilter>): HttpParams {
  (Object.keys(filter ?? {}) as Array<keyof typeof filter>).forEach((filterProperty: Partial<keyof CockpitFilter>) => {
    if (filter[filterProperty]) {
      httpParams = httpParams.append(filterProperty, filter[filterProperty] as string | boolean);
    }
  });

  return httpParams.delete('withDouggieMentions');
}

function buildTaskPageParams(
  httpParams: HttpParams,
  page: CockpitPage,
  quoteStatus: CockpitQuoteStatus | undefined,
): HttpParams {
  if (page === 'to-assign') {
    httpParams = httpParams.append('assignStatus', 'collaborator-unassigned');
    httpParams = httpParams.append('status', 'active');
  }

  if (page === 'to-do') {
    httpParams = httpParams.append('assignStatus', 'collaborator-assigned');
    httpParams = httpParams.append('status', 'active');
  }

  if (page === 'completed') {
    httpParams = httpParams.append('assignStatus', 'collaborator-assigned');
    httpParams = httpParams.append('status', 'completed');
  }

  if (page === 'delegated') {
    httpParams = httpParams.append('assignStatus', 'collaborator-assigned');
    httpParams = httpParams.append('status', 'delegated');
  }

  if (page === 'quotes' && quoteStatus) {
    httpParams = httpParams.append('status', 'delegated');
    httpParams = httpParams.append('quoteStatus', quoteStatus);
    httpParams = httpParams.append('assignStatus', 'collaborator-assigned');
  }

  return httpParams;
}

function setTaskSortParams(
  httpParams: HttpParams,
  lastBlock?: CurrentTasksPageData | undefined | null,
  page?: CockpitPage,
  sort?: CockpitSort,
): HttpParams {
  if (sort) {
    httpParams = httpParams.append('sortBy', sort);
  }

  if (page === 'completed') {
    httpParams = httpParams.set('sortBy', 'completed-at');
  }

  if (!lastBlock) {
    return httpParams;
  }

  const {
    lastClosingDate,
    lastLastName,
    lastCompanyName,
    lastCompletedAt,
    lastId,
    lastStartDate,
    lastTaskMentionWasSeen,
  } = lastBlock;

  httpParams = httpParams.append('lastId', lastId);

  if (sort === 'start-date' && lastStartDate) {
    httpParams = httpParams.append(
      'lastStartDate',
      lastStartDate.toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
    );
  }

  if (sort === 'closing-date' && lastClosingDate) {
    httpParams = httpParams.append(
      'lastClosingDate',
      lastClosingDate.toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
    );
  }

  if ((page === 'completed' || 'completed-at') && lastCompletedAt) {
    httpParams = httpParams.append(
      'lastCompletedAt',
      lastCompletedAt.toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
    );
  }

  if (sort === 'company-name' && (lastCompanyName || lastLastName)) {
    if (lastCompanyName) {
      httpParams = httpParams.append('lastCompanyName', lastCompanyName);
    }

    if (lastLastName) {
      httpParams = httpParams.append('lastLastName', lastLastName);
    }
  }

  if (page === 'mentions') {
    httpParams = httpParams.append('lastTaskMentionWasSeen', lastTaskMentionWasSeen);
  }

  return httpParams;
}

function setTaskPartitionParams(httpParams: HttpParams, partition: Readonly<CockpitPartition>): HttpParams {
  if (!partition?.dates) {
    return httpParams;
  }

  if (partition?.partitionBy) {
    httpParams = httpParams.append('intervalBy', partition.partitionBy);
  }

  if (partition?.dates.from) {
    httpParams = httpParams.append(
      'intervalFrom',
      partition.dates.from.toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
    );
  }

  if (partition?.dates.to) {
    httpParams = httpParams.append(
      'intervalTo',
      partition.dates.to.toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
    );
  }

  if (httpParams.has('sortBy') && partition?.dates.to && !partition?.dates.from) {
    httpParams = httpParams.append('sortDirection', 'DESC');
  }

  return httpParams;
}
