export * from './activity.dto';
export * from './comment.dto';
export * from './create-task.dto';
export * from './filter-task.dto';
export * from './order-by-task.dto';
export * from './tag.dto';
export * from './task.dto';
export * from './task-form.dto';
export * from './task-template-category.dto';
export * from './task-template.dto';
export * from './task-esign-procedure.dto';
export * from './task-configurator.dto';
export * from './stats/assignee-stats.dto';
export * from './stats/category-stats.dto';
export * from './cockpit';
export * from './tasks';
export * from './task-params.dto';
export * from './slot-document.dto';
export * from './quality-control-remarks/quality-control-remarks.dto';
export * from './legal-state.dto';
export * from './payslip';
