import { inject } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { mergeObjects } from '@dougs/core/utils';
import { BillingInvoiceItem } from '@dougs/subscription/dto';
import {
  Comment,
  CommentPost,
  CreateTaskDto,
  Task,
  TASK_LEGAL_STATE,
  TaskBillingInvoiceItem,
  TaskConfiguratorContext,
  TaskESignProcedure,
  TaskForm,
  TaskFormInlineGroupValueItem,
} from '@dougs/task/dto';
import { TaskHttpService } from '../../http/task.http';
import { CommentService } from '../../services/comment.service';
import { TasksEventsService } from '../../services/tasks-events.service';

export abstract class AbstractTaskState<T> extends StateService<T & { tasks: Task[] }> {
  protected readonly tasksEventsService: TasksEventsService = inject(TasksEventsService);
  protected readonly taskHttpService: TaskHttpService = inject(TaskHttpService);
  protected readonly logger: LoggerService = inject(LoggerService);
  protected readonly commentService: CommentService = inject(CommentService);

  protected constructor() {
    super();
    this.tasksEventsService.updateTask$.subscribe((task: Task) => {
      this.handleTaskModification(task);
    });

    this.tasksEventsService.removeTask$.subscribe((task: Task) => this.removeTaskState(task));
  }

  public tasks$: Observable<Task[]> = this.select((state) => state.tasks);

  public abstract refreshTasks(...args: any): Promise<any>;

  protected activeFilters: ((task: Task) => boolean)[] = [];

  protected handleTaskModification(task: Task): void {
    const taskExistInState: boolean = this.taskExistInState(task);
    const isInFilter: boolean = this.isInFilter(task);

    if (taskExistInState) {
      if (isInFilter) {
        this.updateTaskState(task);
      } else {
        this.removeTaskState(task);
      }
    } else if (isInFilter) {
      this.addTaskState(task);
    }
  }

  protected updateTaskState(task: Task): void {
    const newTasks: Task[] = this.state.tasks.map((taskIterated) =>
      taskIterated.id === task.id ? mergeObjects(taskIterated, task) : taskIterated,
    );

    this.setState({
      tasks: newTasks,
    } as Partial<T & { tasks: Task[] }>);
  }

  protected removeTaskState(task: Task): void {
    const newTasks: Task[] = this.state.tasks.filter((taskIterated) => taskIterated.id !== task.id);
    this.setState({
      tasks: newTasks,
    } as Partial<T & { tasks: Task[] }>);
  }

  protected addTaskState(task: Task): void {
    if (!this.taskExistInState(task)) {
      const newTasks: Task[] = this.state?.tasks?.length ? [...this.state.tasks, task] : [task];
      this.setState({
        tasks: newTasks,
      } as Partial<T & { tasks: Task[] }>);
    }
  }

  protected isInFilter(task: Task): boolean {
    if (!this.activeFilters.length) {
      return false;
    }

    return this.activeFilters.every((filterCallback) => filterCallback(task));
  }

  async createTask(task: CreateTaskDto): Promise<Task | null> {
    try {
      const taskCreated: Task = await lastValueFrom(this.taskHttpService.createTask(task));

      this.tasksEventsService.propagateUpdateTask(taskCreated);

      return taskCreated;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async getTaskNote(userId: number): Promise<Task | null> {
    try {
      return await lastValueFrom(this.taskHttpService.getTaskNote(userId));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async abortTask(task: Task, abortionReasonCode: string, abortionReasonMessage: string): Promise<boolean> {
    try {
      const taskAborted: Task = await lastValueFrom(
        this.taskHttpService.abortTask(task, abortionReasonCode, abortionReasonMessage),
      );
      this.tasksEventsService.propagateUpdateTask(taskAborted);
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async updateTask(task: Task): Promise<void> {
    try {
      const taskUpdated: Task = await lastValueFrom(this.taskHttpService.updateTask(task));
      this.tasksEventsService.propagateUpdateTask(taskUpdated);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateTasksStartDate(tasks: Task[], startDate: string): Promise<boolean> {
    try {
      const tasksUpdated: Task[] = await lastValueFrom(
        this.taskHttpService.bulkUpdateTasks(
          tasks.map((task) => task.id),
          startDate,
        ),
      );

      for (const taskUpdated of tasksUpdated) {
        this.tasksEventsService.propagateUpdateTask(taskUpdated);
      }

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async updateTaskLegalState(task: Task, legalState: TASK_LEGAL_STATE | null): Promise<boolean> {
    try {
      const taskUpdated: Task = await lastValueFrom(this.taskHttpService.updateTaskLegalState(task, legalState));
      if (taskUpdated) {
        this.tasksEventsService.propagateUpdateTask(taskUpdated);
      }
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async updateTasksAssignee(tasks: Task[], assigneeId: number | null, startDate?: Date | string): Promise<boolean> {
    try {
      const tasksUpdated: Task[] = await lastValueFrom(
        this.taskHttpService.bulkAssignTasks(
          tasks.map((task) => task.id),
          assigneeId,
          startDate,
        ),
      );

      for (const taskUpdated of tasksUpdated) {
        this.tasksEventsService.propagateUpdateTask(taskUpdated);
      }

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async updateTaskDueDate(task: Task, dueDate: string, reason?: string): Promise<boolean> {
    try {
      const taskUpdated: Task = await lastValueFrom(this.taskHttpService.updateTaskDueDate(task, dueDate, reason));
      this.tasksEventsService.propagateUpdateTask(taskUpdated);

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async updateTaskStartDate(task: Task, startDate: string): Promise<boolean> {
    try {
      const taskUpdated: Task = await lastValueFrom(this.taskHttpService.updateTaskStartDate(task, startDate));
      this.tasksEventsService.propagateUpdateTask(taskUpdated);

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async priorizeTask(task: Task): Promise<boolean> {
    try {
      const taskUpdated: Task = await lastValueFrom(this.taskHttpService.priorizeTask(task.id));
      this.tasksEventsService.propagateUpdateTask(taskUpdated);

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async depriorizeTask(task: Task): Promise<boolean> {
    try {
      const taskUpdated: Task = await lastValueFrom(this.taskHttpService.depriorizeTask(task.id));
      this.tasksEventsService.propagateUpdateTask(taskUpdated);

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async completeTask(
    task: Task,
    isSubTask = false,
    bypass = false,
    completionReasonCode?: string,
    completionReasonMessage?: string,
  ): Promise<boolean> {
    try {
      const taskUpdated: Task = await lastValueFrom(
        this.taskHttpService.completeTask(task.id, bypass, completionReasonCode, completionReasonMessage),
      );

      this.tasksEventsService.propagateUpdateTask(
        isSubTask && task.rootId ? await lastValueFrom(this.taskHttpService.getTask(task.rootId)) : taskUpdated,
      );
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async unCompleteTask(task: Task, isSubTask = false): Promise<boolean> {
    try {
      const taskUpdated: Task = await lastValueFrom(this.taskHttpService.unCompleteTask(task.id));
      this.tasksEventsService.propagateUpdateTask(
        isSubTask && task.rootId ? await lastValueFrom(this.taskHttpService.getTask(task.rootId)) : taskUpdated,
      );
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async commentTask(task: Task, comment: CommentPost): Promise<void> {
    try {
      const commentUpdated: Comment = await lastValueFrom(this.taskHttpService.commentTask(task.id, comment));
      this.tasksEventsService.propagateUpdateTask({
        ...task,
        comments: [...(task.comments || []), commentUpdated],
        latestComment: commentUpdated || task?.latestComment,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateComment(task: Task, comment: Comment): Promise<void> {
    try {
      const commentUpdated: Comment = await lastValueFrom(this.taskHttpService.updateCommentTask(task.id, comment));

      const comments: Comment[] = task.comments.map((commentIterated) =>
        commentIterated.id === commentUpdated.id ? commentUpdated : commentIterated,
      );
      const notDeletedComments: Comment[] = comments.filter(
        (comment) => !comment.tags.some((tag) => tag.type === 'deleted'),
      );
      this.tasksEventsService.propagateUpdateTask({
        ...task,
        comments,
        latestComment: notDeletedComments?.[notDeletedComments.length - 1] || task.latestComment,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadCommentAttachments(task: Task, comment: Comment, files: FileList): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) =>
          lastValueFrom(this.taskHttpService.uploadCommentAttachment(task.id, comment.id, file)),
        ),
      );
      this.tasksEventsService.propagateUpdateTask(
        this.commentService.addAttachmentsToCommentTask(task, comment, attachments),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removeCommentAttachment(task: Task, attachment: Attachment): Promise<void> {
    try {
      await lastValueFrom(this.taskHttpService.deleteCommentAttachment(task.id, attachment));
      this.tasksEventsService.propagateUpdateTask(this.commentService.removeAttachmentToCommentTask(task, attachment));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getTaskAttachments(task: Task): Promise<Attachment[] | null> {
    try {
      const attachments: Attachment[] = await lastValueFrom(this.taskHttpService.getTaskAttachments(task.id));
      this.tasksEventsService.propagateUpdateTask({ ...task, attachments });
      return attachments;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async uploadTaskAttachment(
    task: Task,
    file: File,
    options: {
      fileType?: string;
      metadata?: { partnerId: number; variableCreatedAt?: string };
    },
  ): Promise<void> {
    try {
      const attachment: Attachment = await lastValueFrom(
        this.taskHttpService.uploadTaskAttachment(task.id, file, options),
      );
      this.tasksEventsService.propagateUpdateTask(this.commentService.addAttachmentToTask(task, attachment));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadTaskAttachments(
    task: Task,
    files: File[],
    options: {
      fileType?: string;
      metadata?: { partnerId: number; variableCreatedAt?: string };
    },
    isSubTask = false,
  ): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        files.map((file: File) => lastValueFrom(this.taskHttpService.uploadTaskAttachment(task.id, file, options))),
      );
      this.tasksEventsService.propagateUpdateTask(
        isSubTask && task.rootId
          ? await lastValueFrom(this.taskHttpService.getTask(task.rootId))
          : this.commentService.addAttachmentsToTask(task, attachments),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removeTaskAttachment(task: Task, attachment: Attachment, isSubTask = false): Promise<void> {
    try {
      await lastValueFrom(this.taskHttpService.deleteTaskAttachment(attachment));
      this.tasksEventsService.propagateUpdateTask(
        isSubTask && task.rootId
          ? await lastValueFrom(this.taskHttpService.getTask(task.rootId))
          : this.commentService.removeAttachmentToTask(task, attachment),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async restoreTask(task: Task): Promise<boolean> {
    try {
      const taskRestored: Task = await lastValueFrom(this.taskHttpService.restoreTask(task));
      this.tasksEventsService.propagateUpdateTask(taskRestored);
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async deleteTask(task: Task): Promise<boolean> {
    try {
      await lastValueFrom(this.taskHttpService.deleteTask(task));
      this.tasksEventsService.propagateRemoveTask(task);
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async getTask(task: Task | number, seen = false): Promise<Task | null> {
    try {
      const updatedTask: Task = await lastValueFrom(
        this.taskHttpService.getTask(typeof task === 'number' ? task : task.id, seen),
      );
      this.tasksEventsService.propagateUpdateTask(updatedTask);
      return updatedTask;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async assignTask(task: Task, assigneeId: number | null): Promise<void> {
    try {
      const taskAssigned: Task = await lastValueFrom(this.taskHttpService.assignTask(task.id, assigneeId));
      this.tasksEventsService.propagateUpdateTask(taskAssigned);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addService(task: Task, billingInvoiceItems: BillingInvoiceItem[]): Promise<boolean> {
    try {
      await lastValueFrom(this.taskHttpService.addService(task, billingInvoiceItems));
      this.tasksEventsService.propagateUpdateTask(await lastValueFrom(this.taskHttpService.getTask(task.id)));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async deleteService(task: Task, billingInvoiceItem: TaskBillingInvoiceItem): Promise<void> {
    try {
      await lastValueFrom(this.taskHttpService.deleteService(task, billingInvoiceItem));
      this.tasksEventsService.propagateUpdateTask(await lastValueFrom(this.taskHttpService.getTask(task.id)));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateTaskWorkload(task: Task, workload: number): Promise<boolean> {
    try {
      await lastValueFrom(this.taskHttpService.updateWorkload(task.id, workload));
      this.tasksEventsService.propagateUpdateTask({ ...task, workload: workload });
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async processTaskConfiguration(task: Task, items: TaskFormInlineGroupValueItem[] | TaskForm): Promise<boolean> {
    try {
      const taskUpdated: Task = await lastValueFrom(this.taskHttpService.processTaskConfiguration(task.id, items));
      this.tasksEventsService.propagateUpdateTask(
        task.rootId ? await lastValueFrom(this.taskHttpService.getTask(task.rootId)) : taskUpdated,
      );
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async processTaskConfigurationV2(task: Task, items: TaskConfiguratorContext): Promise<boolean> {
    try {
      const taskUpdated: Task = await lastValueFrom(
        this.taskHttpService.processTaskConfigurationV2(task.parentId ?? task.id, items),
      );
      this.tasksEventsService.propagateUpdateTask(
        task.rootId ? await lastValueFrom(this.taskHttpService.getTask(task.rootId)) : taskUpdated,
      );
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async updateTaskConfigurationV2(taskId: number, items: TaskConfiguratorContext): Promise<boolean> {
    try {
      await lastValueFrom(this.taskHttpService.updateTaskConfigurationV2(taskId, items));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async createESignProcedure(
    taskId: number,
    partnerIds: number[],
    filesIds: number[],
  ): Promise<TaskESignProcedure | null> {
    try {
      const taskESignProcedure: TaskESignProcedure = await lastValueFrom(
        this.taskHttpService.createESignProcedure(taskId, partnerIds, filesIds),
      );
      this.tasksEventsService.propagateUpdateTask(taskESignProcedure.task);
      return taskESignProcedure;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async makeDocumentsAvailable(task: Task, fileAttachmentIds: number[]): Promise<boolean> {
    try {
      await lastValueFrom(this.taskHttpService.makeDocumentsAvailable(task.id, fileAttachmentIds));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  protected taskExistInState(task: Task): boolean {
    return !!this.state?.tasks?.find((taskIterated) => taskIterated.id === task.id);
  }
}
