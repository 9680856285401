import { Profile } from '@dougs/user/dto';
import { QualityControlRemark } from '../dto/quality-control-remarks/quality-control-remarks.dto';

const getDefaults = (): QualityControlRemark => ({
  completer: {} as Profile,
  creator: {} as Profile,
  completedAt: new Date('2023-01-01'),
  createdAt: new Date('2023-01-01'),
  isCompleted: false,
  body: 'body',
  id: 1,
});

export const getQualityControlRemark = (p?: Partial<QualityControlRemark>): QualityControlRemark => ({
  ...getDefaults(),
  ...p,
});
