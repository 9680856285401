import { getUserMock } from '@dougs/user/dto';
import { AssigneeStats } from '../dto';

const getDefaults = (): AssigneeStats => ({
  assigneeId: 1,
  assignee: getUserMock(),
  filter: 'none',
  overdueTaskCount: 0,
  rootTaskCount: 0,
});

export const getAssigneeStatsMock = (p?: Partial<AssigneeStats>): AssigneeStats => ({
  ...getDefaults(),
  ...p,
});
