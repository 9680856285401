import { Injectable } from '@angular/core';
import { addMinutes } from 'date-fns';
import { catchError, lastValueFrom, map, Observable, of, take } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import {
  CockpitAccountingTeamStats,
  CockpitCategorySummary,
  CockpitCollaboratorStats,
  CockpitDomain,
  CockpitFilter,
  CockpitPage,
  CockpitParameters,
  CockpitPartition,
  CockpitQuoteStatus,
  CockpitSort,
  CockpitStateAction,
  CockpitTask,
  CockpitTeamStats,
  CockpitUnseenStats,
  CurrentTasksPageRegister,
  GetTasksReason,
  Task,
  TaskDepartment,
} from '@dougs/task/dto';
import { Collaborator, Team, User } from '@dougs/user/dto';
import { CollaboratorStateService, TeamStateService, UserStateService } from '@dougs/user/shared';
import { CockpitHttpService } from '../http/cockpit.http';
import { TaskHttpService } from '../http/task.http';
import {
  COCKPIT_DEFAULT_ACCOUNTING_TEAM_STATS,
  COCKPIT_DEFAULT_CATEGORY,
  COCKPIT_DEFAULT_CODE,
  COCKPIT_DEFAULT_COLLABORATOR,
  COCKPIT_DEFAULT_DEPARTMENT,
  COCKPIT_DEFAULT_DOMAIN,
  COCKPIT_DEFAULT_FILTER,
  COCKPIT_DEFAULT_LAST_BLOCK_METADATA,
  COCKPIT_DEFAULT_PAGE,
  COCKPIT_DEFAULT_PARTITION,
  COCKPIT_DEFAULT_QUOTE_STATUS,
  COCKPIT_DEFAULT_SORT,
  COCKPIT_DEFAULT_TASKS,
  COCKPIT_DEFAULT_TEAM,
  COCKPIT_DEFAULT_UNSEEN_STATS,
} from './cockpit-state-utils/cockpit-defaults.state.util';
import { CockpitError } from './cockpit-state-utils/cockpit-error.state.util';
import {
  GetCockpitSummaryObservablFnParameters,
  getTasksSummaries,
} from './cockpit-state-utils/cockpit-summary.state.util';
import {
  getCockpitTasksObservable,
  GetCockpitTasksObservableFnParameters,
  shouldTaskStayInStateWithCurrentParameters as shouldTaskStayWithCurrentParameters,
} from './cockpit-state-utils/cockpit-tasks.state.util';

type CockpitTaskMap = ReadonlyMap<number, Readonly<CockpitTask>>;

type CockpitStateIndicators = {
  parametersInSyncWithBackend: boolean;
};

type CockpitState = CockpitParameters &
  CockpitStateIndicators & {
    tasks: CockpitTaskMap;
    unseenStats: CockpitUnseenStats;
    // todo : renommer, ce n'est pas des lastBlockMetadata, c'est le registre des pages courrante
    lastBlockMetadata: CurrentTasksPageRegister | null;
    accountingTeamStats: CockpitAccountingTeamStats;
  };

@Injectable({
  providedIn: 'root',
})
export class CockpitStateService extends StateService<CockpitState> {
  private lastTaskScrappedCount = 0;
  private readonly stateHistory: CockpitStateAction[] = [];

  private readonly TASK_SCRAPPING_PAGE_SIZE = 20;

  private readonly STATE_DEFAULTS: Readonly<{ [Key in keyof CockpitState]: CockpitState[Key] }> = {
    category: COCKPIT_DEFAULT_CATEGORY,
    code: COCKPIT_DEFAULT_CODE,
    collaborator: COCKPIT_DEFAULT_COLLABORATOR,
    department: COCKPIT_DEFAULT_DEPARTMENT,
    domain: COCKPIT_DEFAULT_DOMAIN,
    filter: COCKPIT_DEFAULT_FILTER,
    page: COCKPIT_DEFAULT_PAGE,
    quoteStatus: COCKPIT_DEFAULT_QUOTE_STATUS,
    sort: COCKPIT_DEFAULT_SORT,
    partition: COCKPIT_DEFAULT_PARTITION,

    tasks: COCKPIT_DEFAULT_TASKS,

    team: COCKPIT_DEFAULT_TEAM,
    unseenStats: COCKPIT_DEFAULT_UNSEEN_STATS,
    lastBlockMetadata: COCKPIT_DEFAULT_LAST_BLOCK_METADATA,
    accountingTeamStats: COCKPIT_DEFAULT_ACCOUNTING_TEAM_STATS,

    parametersInSyncWithBackend: false,
  };

  readonly category$ = this.selectWithDefault('category');
  readonly code$ = this.selectWithDefault('code');
  readonly collaborator$ = this.selectWithDefault('collaborator');
  readonly department$ = this.selectWithDefault('department');
  readonly domain$ = this.selectWithDefault('domain');
  readonly filter$ = this.selectWithDefault('filter');
  readonly page$ = this.selectWithDefault('page');
  readonly quoteStatus$ = this.selectWithDefault('quoteStatus');
  readonly sort$ = this.selectWithDefault('sort');
  readonly partition$ = this.selectWithDefault('partition');

  readonly tasks$ = this.selectWithDefault('tasks');

  readonly team$ = this.selectWithDefault('team');
  readonly unseenStats$ = this.selectWithDefault('unseenStats');
  readonly lastBlockMetadata$ = this.selectWithDefault('lastBlockMetadata');

  readonly parametersInSyncWithBackend$ = this.selectWithDefault('parametersInSyncWithBackend');
  readonly accountingTeamStats$ = this.selectWithDefault('accountingTeamStats');

  readonly totalUnseen$: Observable<number> = this.unseenStats$.pipe(
    map((unseenStats: CockpitUnseenStats | undefined) => (unseenStats?.tasks ?? 0) + (unseenStats?.mentions ?? 0)),
  );

  private selectWithDefault<Key extends keyof CockpitState>(key: Key): Observable<CockpitState[Key]> {
    return this.select((state) => (state[key] === undefined ? this.STATE_DEFAULTS[key] : state[key]));
  }

  get<Key extends keyof CockpitState>(key: Key): Readonly<CockpitState[Key]> {
    return this.state[key] === undefined ? this.STATE_DEFAULTS[key] : this.state[key];
  }

  getStateHistory(): Readonly<CockpitStateAction>[] {
    return this.stateHistory;
  }

  constructor(
    private readonly collaboratorStateService: CollaboratorStateService,
    private readonly teamStateService: TeamStateService,
    private readonly userStateService: UserStateService,
    private readonly cockpitHttpService: CockpitHttpService,
    private readonly taskHttpService: TaskHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  getCollaboratorStats(collaboratorId: number, referenceDate: Date): Observable<CockpitCollaboratorStats> {
    return this.cockpitHttpService.getCockpitCollaboratorStats(collaboratorId, referenceDate).pipe(
      map((collaboratorStats: CockpitCollaboratorStats) => {
        return collaboratorStats;
      }),
      catchError((e) => {
        this.logger.error(e);
        return of();
      }),
    );
  }

  getTeamStats(teamId: number, referenceDate: Date): Observable<CockpitCollaboratorStats[]> {
    return this.cockpitHttpService.getCockpitTeamStats(teamId, referenceDate).pipe(
      map((teamStats: CockpitCollaboratorStats[]) => {
        return teamStats;
      }),
      catchError((e) => {
        this.logger.error(e);
        return of([]);
      }),
    );
  }

  getDepartmentStats(department: string, referenceDate: Date): Observable<CockpitTeamStats[]> {
    return this.cockpitHttpService.getCockpitDepartmentStats(department, referenceDate).pipe(
      map((departmentStats: CockpitTeamStats[]) => {
        return departmentStats;
      }),
      catchError((e) => {
        this.logger.error(e);
        return of([]);
      }),
    );
  }

  getSummaries(summariesPayload: GetCockpitSummaryObservablFnParameters): Observable<CockpitCategorySummary[]> {
    return getTasksSummaries(summariesPayload, this.taskHttpService).pipe(
      map((summaries: CockpitCategorySummary[]) => {
        return summaries;
      }),
      catchError((e) => {
        this.logger.error(e);
        return of([]);
      }),
    );
  }

  appendTasks(
    getReason: GetTasksReason,
    tasksPayload: GetCockpitTasksObservableFnParameters,
    lastBlockMetadata: CurrentTasksPageRegister | null,
  ): Observable<CockpitTaskMap> {
    return this.fillTaskState(getReason, tasksPayload, lastBlockMetadata);
  }

  resetTask(tasksPayload: GetCockpitTasksObservableFnParameters): Observable<CockpitTaskMap> {
    // Permet de déjà nettoyer ce qui utilise l'observable, en attendant le retour du back.
    // Cela permet aussi de décharger du travail lors de l'affichage des composants, côté Angular.
    this.resetTaskState();
    return this.fillTaskState('reset', tasksPayload);
  }

  private fillTaskState(
    getReason: GetTasksReason,
    tasksPayload: GetCockpitTasksObservableFnParameters,
    lastBlockMetadataMap?: CurrentTasksPageRegister | null,
  ): Observable<CockpitTaskMap> {
    // ? est ce que c'est pour mettre les raisons : 'unseen-tasks' | 'unseen-mentions' à "append"
    const insertionMode: 'reset' | 'append' = getReason === 'reset' ? 'reset' : 'append';

    if (
      insertionMode === 'append' &&
      // todo : logique de page ne devrait pas être là
      tasksPayload.page !== 'unseen' &&
      this.lastTaskScrappedCount < this.TASK_SCRAPPING_PAGE_SIZE
    ) {
      return of(this.state.tasks);
    }

    return getCockpitTasksObservable(getReason, tasksPayload, this.taskHttpService, lastBlockMetadataMap).pipe(
      map(({ scrappedTasks, blocks }) => {
        const tasks: Map<number, Readonly<CockpitTask>> = new Map<number, Readonly<CockpitTask>>(
          insertionMode === 'append' ? this.state.tasks : [],
        );

        scrappedTasks.forEach((task: Readonly<CockpitTask>) =>
          tasks.set(
            task.id,
            Object.freeze({
              ...task,
              startDate: new Date(task.startDate),
              completedAt: task.completedAt ? new Date(task.completedAt) : null,
              companyClosingDate: task.companyClosingDate ? new Date(task.companyClosingDate) : null,
            }),
          ),
        );

        this.lastTaskScrappedCount = scrappedTasks.length;

        this.setState({ tasks, parametersInSyncWithBackend: true });

        if (blocks) {
          blocks.forEach(({ blockId, lastId }) => {
            const task: Readonly<CockpitTask> | undefined = tasks.get(lastId ?? 0);
            if (!task) {
              return;
            }

            this.updateLastBlockMetadataState(blockId, task);
          });

          return this.state.tasks;
        }

        // Dans ce cas, le lastBlockMetadata à définir (éventuellement) sera celui par défaut.
        const lastTaskFromPage: Readonly<CockpitTask> | null = scrappedTasks.length
          ? tasks.get(scrappedTasks[scrappedTasks.length - 1]?.id ?? 0) ?? null
          : null;

        if (lastTaskFromPage) {
          this.updateLastBlockMetadataState('default', lastTaskFromPage);
        }

        return this.state.tasks;
      }),
      catchError((e) => {
        this.logger.error(e);

        if (insertionMode === 'reset') {
          this.setState({ tasks: new Map() });
          this.resetLastBlockMetadataState();
        }

        return of(this.state.tasks);
      }),
    );
  }

  resetLastBlockMetadataState(): void {
    this.setState({ lastBlockMetadata: null });
  }

  private updateLastBlockMetadataState(
    blockId: string,
    {
      id,
      startDate,
      completedAt,
      companyClosingDate,
      allMentionsAreSeen,
      companyLegalName,
      targetLastName,
    }: CockpitTask,
  ): void {
    this.setState({
      lastBlockMetadata: new Map(this.state.lastBlockMetadata).set(blockId, {
        lastId: id,
        lastStartDate: startDate,
        lastCompletedAt: completedAt,
        lastClosingDate: companyClosingDate,
        lastTaskMentionWasSeen: !!allMentionsAreSeen,
        lastCompanyName: companyLegalName,
        lastLastName: targetLastName,
      }),
    });
  }

  async refreshUnseenStatsOfCurrentUser(): Promise<void> {
    const currentUser: User = await lastValueFrom(this.userStateService.loggedInUser$.pipe(take(1)));

    const collaborator: Readonly<Collaborator> = (
      (await lastValueFrom(this.collaboratorStateService.collaborators$.pipe(take(1)))) ?? new Map()
    ).get(currentUser.id);
    if (!collaborator) {
      return;
    }

    return this.refreshUnseenStats(collaborator.id);
  }

  async refreshUnseenStats(collaboratorId: number): Promise<void> {
    try {
      const unseenStats: CockpitUnseenStats = await lastValueFrom(
        this.cockpitHttpService.getCockpitUnseenStats(collaboratorId),
      );

      this.setState({
        unseenStats,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async bulkAssignTasks(taskIds: number[], assigneeId: number | null, startDate?: Date): Promise<void> {
    try {
      await lastValueFrom(this.taskHttpService.bulkAssignTasks(taskIds, assigneeId, startDate));

      await this.updateTasksState(
        taskIds.map((taskId) =>
          Object.freeze({
            id: taskId,
            ...(assigneeId ? { assigneeId } : {}),
            ...(startDate ? { startDate } : {}),
          }),
        ),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  removeTasksFromState(taskIds: number[]): void {
    const tasks: Map<number, Readonly<CockpitTask>> = new Map(this.state.tasks);
    taskIds.forEach((taskId: number) => tasks.delete(taskId));
    this.setState({ tasks });
  }

  updateCategoryState(category: string | null): void {
    this.updateParameterState('category', category);
  }

  updateCodeState(code: string | null): void {
    this.updateParameterState('code', code);
  }

  updateQuoteStatusState(quoteStatus: CockpitQuoteStatus): void {
    this.updateParameterState('quoteStatus', quoteStatus);
  }

  updateCollaboratorState(collaborator: Collaborator | null): void {
    this.updateParameterState('collaborator', collaborator);
  }

  updateTeamState(team: Team | null): void {
    this.updateParameterState('team', team);
  }

  updateDepartmentState(department: TaskDepartment | null): void {
    this.updateParameterState('department', department);
  }

  updateDomainState(domain: CockpitDomain): void {
    this.updateParameterState('domain', domain);
  }

  updateFilterState(filter: CockpitFilter): void {
    this.updateParameterState('filter', filter);
  }

  updatePageState(page: CockpitPage): void {
    this.updateParameterState('page', page);
  }

  updateSortState(sort: CockpitSort): void {
    this.updateParameterState('sort', sort);
  }

  updatePartitionState(partition: Readonly<CockpitPartition> | null): void {
    this.updateParameterState('partition', partition);
  }

  private updateParameterState<StateName extends keyof CockpitParameters>(
    parameter: StateName,
    value: CockpitParameters[StateName],
  ): void {
    this.stateHistory.push({ type: 'update-parameter', parameter, value: JSON.stringify(value) });
    this.setState({
      [parameter]: value,
      parametersInSyncWithBackend: this.state[parameter] === value,
    });
  }

  async updateTasksState(updateTaskPayload: (Partial<Readonly<CockpitTask>> & { id: number })[]): Promise<void> {
    const collaborators: ReadonlyMap<number, Readonly<Collaborator>> | undefined = await lastValueFrom(
      this.collaboratorStateService.collaborators$.pipe(take(1)),
    );

    const teams: ReadonlyMap<number, Readonly<Team>> | undefined = await lastValueFrom(
      this.teamStateService.teams$.pipe(take(1)),
    );

    const tasks: Map<number, Readonly<CockpitTask>> = new Map(this.state.tasks ?? []);

    let shouldUpdateTasksState = false;
    updateTaskPayload.forEach((taskPayload: Partial<Readonly<CockpitTask>> & { id: number }) => {
      const task: Readonly<CockpitTask> | undefined = tasks.get(taskPayload.id);

      if (!task) {
        return;
      }

      const updatedTask: Readonly<CockpitTask> = Object.freeze({ ...task, ...taskPayload });

      if (
        !shouldTaskStayWithCurrentParameters(
          {
            page: this.state.page,
            collaborator: this.state.collaborator,
            domain: this.state.domain,
            team: this.state.team,
            department: this.state.department,
            task: updatedTask,
            assigneeCollaborator: collaborators?.get(updatedTask.assigneeId) ?? null,
            assigneeTeam: teams?.get(updatedTask.assigneeId) ?? null,
            filter: this.state.filter,
            partition: this.state.partition,
          },
          this.teamStateService,
        )
      ) {
        tasks.delete(updatedTask.id);
      } else {
        tasks.set(updatedTask.id, updatedTask);
      }

      shouldUpdateTasksState = true;
    });

    if (shouldUpdateTasksState) {
      this.setState({ tasks });
    }
  }

  resetCategoryState(): void {
    this.updateCategoryState(COCKPIT_DEFAULT_CATEGORY);
  }

  resetCodeState(): void {
    this.updateCodeState(COCKPIT_DEFAULT_CODE);
  }

  resetTaskState(): void {
    this.setState({ tasks: COCKPIT_DEFAULT_TASKS });
  }

  async updateTaskPriority(taskId: number, priority: boolean): Promise<void> {
    const previousTaskPriority: undefined | boolean = this.state.tasks.get(taskId)?.isPriority;

    if (previousTaskPriority === undefined) {
      throw new CockpitError('This task does not have priority or does not exist.');
    }

    try {
      await this.updateTasksState([{ id: taskId, isPriority: priority }]);
      await lastValueFrom(
        priority ? this.taskHttpService.priorizeTask(taskId) : this.taskHttpService.depriorizeTask(taskId),
      );
    } catch (e) {
      await this.updateTasksState([{ id: taskId, isPriority: previousTaskPriority }]);
      this.logger.error(e);
    }
  }

  async updateTaskSeenState(taskId: number, seen: boolean): Promise<void> {
    try {
      await this.updateTasksState([{ id: taskId, allMentionsAreSeen: seen, taskHasBeenSeenAfterCreation: seen }]);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateTaskStartDate(task: Task, startDate: Date): Promise<void> {
    try {
      await lastValueFrom(
        this.taskHttpService.updateTaskStartDate(
          task,
          addMinutes(startDate, -startDate.getTimezoneOffset()).toString(),
        ),
      );
      await this.updateTasksState([{ id: task.id, startDate }]);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshAccountingTeamStats(team: Team, startDate: Date): Promise<CockpitAccountingTeamStats | null> {
    try {
      const cockpitAccountingStats: CockpitAccountingTeamStats = await lastValueFrom(
        this.cockpitHttpService.getAccountingTeamStats(team.id, startDate),
      );
      this.setState({
        accountingTeamStats: cockpitAccountingStats,
      });
      return cockpitAccountingStats;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }
}
