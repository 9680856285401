import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { Task } from '@dougs/task/dto';
import { TaskHttpService } from '../../http/task.http';

@Injectable({
  providedIn: 'root',
})
export class FourretoutTaskStateService {
  constructor(
    private readonly taskHttpService: TaskHttpService,
    private readonly logger: LoggerService,
  ) {}

  async updateInvoiceTaskSettings(task: Task): Promise<void> {
    try {
      await lastValueFrom(this.taskHttpService.updateInvoiceTaskSettings(task));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getTaskRemarks(task: Task): Promise<string | null> {
    try {
      return await lastValueFrom(this.taskHttpService.getTaskRemarks(task.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async askCustomerForInformation(
    task: Task,
    subject: string,
    question: string,
    daysToReminder: number,
    daysToDueDate: number,
  ): Promise<void> {
    try {
      await lastValueFrom(
        this.taskHttpService.askCustomerForInformation(task, subject, question, daysToReminder, daysToDueDate),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getNegativeAssociateAccountTaskTitles(company: Company): Promise<string[]> {
    try {
      return await lastValueFrom(this.taskHttpService.getNegativeAssociateAccountTaskTitles(company.id));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  async getAttachmentsByIds(task: Task, ids: number[]): Promise<Attachment[]> {
    try {
      return await lastValueFrom(this.taskHttpService.getAttachmentsByIds(task, ids));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  async getActionableTasksByCodes(company: Company, taskCodes: string[]): Promise<Task[]> {
    try {
      return await lastValueFrom(this.taskHttpService.getCustomerTasks(company.id, taskCodes));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }
}
