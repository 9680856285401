import { HttpParams } from '@angular/common/http';
import { isAfter, isBefore } from 'date-fns';
import { combineLatest, map, Observable } from 'rxjs';
import {
  CockpitFilter,
  CockpitPage,
  CockpitParameters,
  CockpitTask,
  CurrentTasksPageData,
  CurrentTasksPageRegister,
  GetTasksReason,
  TaskDepartment,
} from '@dougs/task/dto';
import { Collaborator, Team } from '@dougs/user/dto';
import { TeamStateService } from '@dougs/user/shared';
import { TaskHttpService } from '../../http/task.http';
import {
  buildTaskHttpParams as buildTaskHttpParams,
  TaskParametersWithoutTarget,
} from './cockpit-build-params.state.util';
import { CockpitError } from './cockpit-error.state.util';

// c'est tout type de tâche qu'on récupère
// on a cette interface et ce système pour gérer le fait d'avoir différent type de tâches dans la même persistance. Utile pour les unseen et unseenMention qui sont sur la même page mais qu'on veut récupérer séparement
export type TaskPageGetted = {
  scrappedTasks: Readonly<CockpitTask>[];

  blocks?: {
    // todo : renommer blockId en type de tâche récupérée
    blockId: string;
    lastId: number | null;
  }[];
};

export type GetCockpitTasksObservableFnParameters = Pick<
  CockpitParameters,
  'page' | 'filter' | 'domain' | 'collaborator' | 'team' | 'department'
> &
  TaskParametersWithoutTarget;

type ShouldTaskStayInStateWithCurrentParametersParameters = Pick<
  CockpitParameters,
  'page' | 'collaborator' | 'team' | 'department' | 'filter' | 'domain' | 'partition'
> & {
  task: CockpitTask;
  assigneeCollaborator: Readonly<Collaborator> | null;
  assigneeTeam: Readonly<Team> | null;
};

export function getCockpitTasksObservable(
  getTaskReason: GetTasksReason,
  parameters: GetCockpitTasksObservableFnParameters,
  taskHttpService: Readonly<TaskHttpService>,
  taskPageDataRegister?: CurrentTasksPageRegister | null,
): Observable<TaskPageGetted> {
  const { page, filter, domain, collaborator, team, department } = parameters;

  const contextualLastBlockMetadata = getCurrentTaskPageDataFromRegister(getTaskReason, page, taskPageDataRegister);

  const params: HttpParams = buildTaskHttpParams(parameters, contextualLastBlockMetadata);

  if (domain === 'department') {
    return pipeTasks(getTasksForDepartment(department, team, page, params, taskHttpService));
  }

  if (domain === 'team') {
    return pipeTasks(getTasksForTeam(team, collaborator, page, params, taskHttpService));
  }

  if (domain === 'collaborator') {
    return getTasksPageForCollaborator(collaborator, filter, page, getTaskReason, params, taskHttpService);
  }

  throw new CockpitError('Please ensure domain is valid while retrieving tasks.');
}

function getCurrentTaskPageDataFromRegister(
  getTasksReason: GetTasksReason,
  page: CockpitPage,
  tasksPageRegister: ReadonlyMap<string, Readonly<CurrentTasksPageData>> | null | undefined,
): CurrentTasksPageData | null {
  if (!tasksPageRegister || !tasksPageRegister.size) {
    return null;
  }

  if (page === 'unseen' && getTasksReason === 'unseen-mentions') {
    return tasksPageRegister.get('unseen-mentions') ?? null;
  }

  if (page === 'unseen' && getTasksReason === 'unseen-tasks') {
    return tasksPageRegister.get('unseen-tasks') ?? null;
  }

  return tasksPageRegister.get('default') ?? null;
}

function getTasksForDepartment(
  department: TaskDepartment | null,
  team: Readonly<Team> | null,
  page: CockpitPage,
  httpParams: HttpParams,
  taskHttpService: Readonly<TaskHttpService>,
): Observable<Readonly<CockpitTask>[]> {
  if (!department) {
    throw new CockpitError('Department should have been set.');
  }

  if (team && ['to-do', 'completed'].includes(page)) {
    return taskHttpService.getTeamTasks(team.userId, httpParams.append('department', department));
  }

  return taskHttpService.getDepartmentTasks(department, httpParams);
}

function getTasksForTeam(
  team: Readonly<Team> | null,
  collaborator: Readonly<Collaborator> | null,
  page: CockpitPage,
  httpParams: HttpParams,
  taskHttpService: Readonly<TaskHttpService>,
): Observable<CockpitTask[]> {
  if (!team) {
    throw new CockpitError('Team should have been set.');
  }

  if (collaborator && ['to-do', 'completed', 'delegated'].includes(page)) {
    return taskHttpService.getCollaboratorTasks(collaborator.id, httpParams.delete('assignStatus'));
  }

  return taskHttpService.getTeamTasks(team.userId, httpParams);
}

function getTasksPageForCollaborator(
  collaborator: Readonly<Collaborator> | null,
  filter: CockpitFilter,
  page: CockpitPage,
  getTasksReason: GetTasksReason,
  httpParams: HttpParams,
  taskHttpService: Readonly<TaskHttpService>,
): Observable<TaskPageGetted> {
  if (!collaborator) {
    throw new CockpitError('Collaborator should have been set.');
  }

  httpParams = httpParams.delete('assignStatus');

  if (page === 'unseen') {
    if (getTasksReason === 'reset') {
      return pipeMultiSourcesTasksBlocks(
        combineLatest([
          taskHttpService.getCollaboratorUnseenTasks(collaborator.id, httpParams),
          taskHttpService.getCollaboratorTasksWithLatestMention(collaborator.id, httpParams.append('isSeen', false)),
        ]),
        ['unseen-tasks', 'unseen-mentions'],
      );
    }

    if (getTasksReason === 'unseen-mentions') {
      return pipeTasksBlocks(
        taskHttpService.getCollaboratorTasksWithLatestMention(collaborator.id, httpParams.append('isSeen', false)),
        'unseen-mentions',
      );
    }

    if (getTasksReason === 'unseen-tasks') {
      return pipeTasksBlocks(taskHttpService.getCollaboratorUnseenTasks(collaborator.id, httpParams), 'unseen-tasks');
    }

    throw new CockpitError('Undefined retrieval method for unseen page.');
  }

  if (page === 'mentions') {
    return pipeTasks(
      taskHttpService.getCollaboratorTasksWithLatestMention(
        collaborator.id,
        httpParams.append('withDouggie', filter.withDouggieMentions),
      ),
    );
  }

  return pipeTasks(taskHttpService.getCollaboratorTasks(collaborator.id, httpParams));
}

export function shouldTaskStayInStateWithCurrentParameters(
  parameters: ShouldTaskStayInStateWithCurrentParametersParameters,
  teamStateService: Readonly<TeamStateService>,
): boolean {
  const { page, domain, partition, collaborator, team, department, task, assigneeCollaborator, assigneeTeam, filter } =
    parameters;
  if (page === 'unseen') {
    return false; // De base, toute tâche "vue" finira non vue, donc sortira du tableau.
    // C'est un petit tweak de performance (léger)
  }

  if (page === 'mentions') {
    return true;
  }

  if (filter.isPriority && !task.isPriority) {
    return false;
  }

  if (page === 'to-assign' && task.assigneeId !== team?.userId && assigneeCollaborator) {
    return false;
  }

  if (page !== 'to-assign' && assigneeTeam) {
    return false;
  }

  if (domain === 'collaborator' && collaborator && task.assigneeId !== collaborator.userId) {
    return false;
  }

  if (partition?.dates) {
    if (partition.dates.from && isBefore(task.startDate, partition.dates.from)) {
      return false;
    }

    if (partition.dates.to && isAfter(task.startDate, partition.dates.to)) {
      return false;
    }
  }

  if (team) {
    // Legacy : les équipes sont aussi des User.
    if (assigneeCollaborator && !assigneeTeam && assigneeCollaborator.teamId !== team.id) {
      return false;
    }

    if (assigneeTeam && assigneeTeam.id !== team.id) {
      return false;
    }
  }

  if (department) {
    if (assigneeTeam && assigneeTeam.department !== department) {
      return false;
    }

    if (
      assigneeCollaborator &&
      !assigneeTeam &&
      teamStateService.getTeamById(assigneeCollaborator.teamId)?.department !== department
    ) {
      return false;
    }
  }

  return true;
}

function pipeTasks(observable: Observable<Readonly<CockpitTask>[]>): Observable<TaskPageGetted> {
  return observable.pipe(
    map((scrappedTasks) => ({
      scrappedTasks,
    })),
  );
}

function pipeTasksBlocks(observable: Observable<Readonly<CockpitTask>[]>, blockId: string): Observable<TaskPageGetted> {
  return observable.pipe(
    map((scrappedTasks) => ({
      scrappedTasks,
      blocks: [
        {
          blockId,
          lastId: scrappedTasks[scrappedTasks.length - 1]?.id ?? 0,
        },
      ],
    })),
  );
}

function pipeMultiSourcesTasksBlocks(
  observable: Observable<Readonly<CockpitTask>[][]>,
  blockIds: string[],
): Observable<TaskPageGetted> {
  return observable.pipe(
    map((scrappedTasks) => ({
      scrappedTasks: scrappedTasks.flat(2),
      blocks: blockIds.map((blockId: string, index: number) => ({
        blockId,
        lastId: scrappedTasks[index][scrappedTasks[index].length - 1]?.id ?? 0,
      })),
    })),
  );
}
