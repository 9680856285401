import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Task } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class TasksEventsService {
  private readonly updateTaskSubject: Subject<Task> = new Subject<Task>();
  public updateTask$: Observable<Task> = this.updateTaskSubject.asObservable();

  private readonly addTaskSubject: Subject<Task> = new Subject<Task>();
  public addTask$: Observable<Task> = this.addTaskSubject.asObservable();

  private readonly removeTaskSubject: Subject<Task> = new Subject<Task>();
  public removeTask$: Observable<Task> = this.removeTaskSubject.asObservable();

  public propagateUpdateTask(Task: Task): void {
    this.updateTaskSubject.next(Task);
  }

  public propagateAddTask(Task: Task): void {
    this.addTaskSubject.next(Task);
  }

  public propagateRemoveTask(Task: Task): void {
    this.removeTaskSubject.next(Task);
  }
}
