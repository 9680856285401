import { CockpitTask } from '../dto';

const getDefaults = (): CockpitTask => ({
  id: 1,
  companyOwnerId: 1,
  companyLegalName: '',
  targetLastName: '',
  startDate: new Date('2023-10-23'),
  dueDate: null,
  abortedAt: null,
  assigneeId: 1,
  code: '',
  title: '',
  category: '',
  companyId: 1,
  companyClosingDate: null,
  isPriority: false,
  completedAt: null,
  completedSubTaskCount: 0,
  targetFirstName: '',
  subTaskCount: 0,
});

export const getCockpitTaskMock = (p?: Partial<CockpitTask>): CockpitTask => ({
  ...getDefaults(),
  ...p,
});
