import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import {
  CollaboratorVariable,
  Payslip,
  PayslipInfo,
  PayslipInfoStatus,
  PayslipModalInfo,
  VariableDescriptors,
} from '@dougs/task/dto';
import { PayslipHttpService } from '../../http/payslip/payslip.http';

interface PayslipState {
  payslipModalInfo: PayslipModalInfo;
  selectedPayslip: Payslip;
  catalog: CollaboratorVariable[];
}

@Injectable({
  providedIn: 'root',
})
export class PayslipStateService extends StateService<PayslipState> {
  constructor(
    private readonly payslipHttpService: PayslipHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  readonly payslipModalInfo$: Observable<PayslipModalInfo> = this.select((state) => state.payslipModalInfo);
  readonly payslipLines$: Observable<PayslipInfo[]> = this.select(
    (state) => state.payslipModalInfo?.payslipInfos ?? [],
  );
  readonly variableDescriptors$: Observable<VariableDescriptors> = this.select(
    (state) => state.payslipModalInfo?.variableDescriptors ?? {},
  );
  readonly selectedPayslip$: Observable<Payslip> = this.select((state) => state.selectedPayslip);
  readonly catalog$: Observable<CollaboratorVariable[]> = this.select((state) => state.catalog);

  async refreshPayslipModalInfos(companyId: number, taskId: number): Promise<void> {
    try {
      const payslipModalInfo: PayslipModalInfo = await toPromise(
        this.payslipHttpService.getPayslipModalInfos(companyId, taskId),
      );
      this.setState({ payslipModalInfo });
    } catch (e) {
      this.logger.error(e);
      this.clearState();
    }
  }

  async refreshPayslipById(companyId: number, payslipId: number): Promise<void> {
    try {
      const payslip: Payslip = await toPromise(this.payslipHttpService.getPayslipById(companyId, payslipId));
      this.setState({ selectedPayslip: payslip });
    } catch (e) {
      this.logger.error(e);
      this.clearSelectedPayslip();
    }
  }

  async refreshVariablesCatalog(companyId: number): Promise<CollaboratorVariable[]> {
    try {
      const variables: CollaboratorVariable[] = await toPromise(
        this.payslipHttpService.getSilaeVariablesCatalog(companyId),
      );
      this.setState({ catalog: variables });
      return variables;
    } catch (e) {
      this.logger.error(e);
      this.setState({ catalog: [] });
      return [];
    }
  }

  async saveCollaboratorVariables(
    companyId: number,
    payslipId: number,
    collaboratorVariables: CollaboratorVariable[],
  ): Promise<void> {
    try {
      await toPromise(this.payslipHttpService.saveCollaboratorVariables(companyId, payslipId, collaboratorVariables));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async validateCollaboratorVariables(companyId: number, payslipId: number): Promise<void> {
    try {
      await toPromise(this.payslipHttpService.validateCollaboratorVariables(companyId, payslipId));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async unvalidateCollaboratorVariables(companyId: number, payslipId: number): Promise<void> {
    try {
      await toPromise(this.payslipHttpService.unvalidateCollaboratorVariables(companyId, payslipId));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async validatePayslip(companyId: number, taskId: number): Promise<boolean> {
    try {
      await toPromise(this.payslipHttpService.validatePayslip(companyId, taskId));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  clearState(): void {
    this.setState({ payslipModalInfo: undefined });
  }

  clearSelectedPayslip(): void {
    this.setState({ selectedPayslip: undefined });
  }

  updatePayslipLineStatus(payslipInfo: PayslipInfo, payslipInfoStatus: PayslipInfoStatus): void {
    const updatedPayslipInfos: PayslipInfo[] = (this.state.payslipModalInfo?.payslipInfos || []).map((info) =>
      info.id === payslipInfo.id ? { ...info, status: payslipInfoStatus } : info,
    );
    this.setState({ payslipModalInfo: { ...this.state.payslipModalInfo, payslipInfos: updatedPayslipInfos } });
  }
}
