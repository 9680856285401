import { TaskForm } from '../dto';

const getDefaults = (): TaskForm => ({
  inlineGroup: {},
  modalGroup: {},
});

export const getTaskFormMock = (p?: Partial<TaskForm>): TaskForm => ({
  ...getDefaults(),
  ...p,
});
