import { Activity } from '../dto';

const getDefaults = (): Activity => ({
  id: '',
  type: '',
  data: {},
  date: new Date('2023-10-23'),
});

export const getActivityMock = (p?: Partial<Activity>): Activity => ({
  ...getDefaults(),
  ...p,
});
